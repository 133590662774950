import "./Footer.css"
import { Link } from "react-router-dom"


const Footer = () => {

    return (
<div className="pb-[100px]">
    <div className="footerContainer relative isolate overflow-hidden mx-10 lg:mx-20 ">
    
    
    <div className="mx-auto max-w-7xl px-1 lg:px-8">

      <div className="mx-auto mt-10 max-w-2xl lg:mx-0 lg:max-w-none">

        <div className="mt-16 grid gap-5 lg:gap-15 sm:mt-20 grid-cols-2 lg:grid-cols-4">
          
            <div className="pl-[10%] lg:pl-[15%] flex flex-col">
              <h3 className="pl-0 lg:pl-2 lg:p-2 text-left text-[8px] lg:text-[14px] leading-[5px] lg:leading-[20px] dark:text-[#FEFEFE]">NAVIGATE</h3>
              <Link to="/home">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Home</h3>
              </Link>
              <Link to="/about">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">About</h3>
              </Link>
              <Link to="/jarvis">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Jarvis</h3>
              </Link>
              <Link to="/careers">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Careers</h3>
              </Link>
              {/* <Link to="/blog">
                  <h3 className="text-[25px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Blog</h3>
              </Link>
              <Link to="/">
                  <h3 className="text-[25px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">AI Ethics</h3>
              </Link> */}
              <Link to="/contact">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Contact</h3>
              </Link>
            </div>

            <div className="pl-[10%] lg:pl-[15%] flex flex-col">
              <h3 className="pl-0 lg:pl-2 lg:p-2 text-left text-[8px] lg:text-[14px] leading-[5px] lg:leading-[20px] dark:text-[#FEFEFE]">CONNECT</h3>
              <Link to="https://www.linkedin.com/company/biorce" target="_blank">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">LinkedIn</h3>
              </Link>
              <Link  to="https://x.com/Wearebiorce" target="_blank">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">X Twitter</h3>
              </Link>
              <Link  to="https://www.instagram.com/wearebiorce/" target="_blank">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Instagram</h3>
              </Link>
              <Link  to="https://www.threads.net/@wearebiorce" target="_blank">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Threads</h3>
              </Link>
            </div>

            <div className="pl-[10%] lg:pl-[15%] flex flex-col">
              <h3 className="pl-0 lg:pl-2 lg:p-2 text-left text-[8px] lg:text-[14px] leading-[5px] lg:leading-[20px] dark:text-[#FEFEFE]">POLICIES</h3>
              <Link  to="https://drive.google.com/file/d/1JGwt5L-gIyATiUWvObwYrVZQX2XjOwb2/view" target="_blank">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Compliance</h3>
              </Link>
              <Link to="https://www.cookiebot.com/en/terms-of-service/" target="_blank">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Cookie policy</h3>
              </Link>
              <Link to="/privacy-Policy">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Privacy policy</h3>
              </Link>
              <Link to="/terms-of-use">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Terms of use</h3>
              </Link>
              <Link to="/faqs">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">FAQ</h3>
              </Link>
            </div>

            <div className="pl-[10%] lg:pl-[15%] flex flex-col">
              <h3 className="pl-0 lg:pl-2 lg:p-2 text-left text-[8px] lg:text-[14px] leading-[5px] lg:leading-[20px] dark:text-[#FEFEFE]">LOCATION</h3>
              <Link to="/contact">
                  <h3 className="text-[20px] lg:text-[40px] text-[#001A4F] font-TTBold lg:font-TTregular leading-[25px] lg:leading-[48px] text-left mt-1 lg:mt-2 lg:opacity-50 hover:opacity-100 dark:text-[#99ECF3]">Barcelona,<br/>Spain</h3>
              </Link>
            </div>

        </div>
        <div className="mt-[30px] lg:mt-[70px] mb-[20px] ml-[3%] text-left dark:text-[#FEFEFE]">
            <p className="text-[10px] lg:text-[18px]">@2024 Biorce | All Rights Reserved</p>
        </div>
      </div>
    </div>
  </div>
  </div>
  )
}

export default Footer

  