
const SubBannerText = () => {
  return (
    <div className="mx-10 lg:mx-20 py-2 flex justify-center items-center self-stretch mt-14 mb-[150px] lg:mb-[300px] 2xl:max-w-7xl 2xl:mx-auto">
        <p className="text-[#001A4F] text-[40px] lg:text-[84px] font-normal leading-[40px] lg:leading-[80px] text-left dark:text-[#99ECF3]">
          Biorce is a pioneering force at the intersection of <strong className="font-TTBold">artificial intelligence</strong> and <strong className="font-TTBold">healthcare.</strong><p className="mt-[120px] lg:mt-[0px]"></p> We are here to go from delays to deliverables, because <strong className="font-TTBold">hope should never wait.</strong>
        </p>
    </div>
  )
}

export default SubBannerText
