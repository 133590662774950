
import BannerHome from '../../components/bannerHome/BannerHome'
import Carousel from '../../components/carousel/Carousel'
import SectionTitle from '../../components/sectionTitle/SectionTitle'
import Partnerships from '../../components/partnerships/Partnerships'
import SubBannerText from '../../components/subBannerText/SubBannerText'
import Subscribe from '../../components/subscribe/Subscribe'
import NewSectionTitle from '../../components/newSectionTitle/NewSectionTitle'
import CasesStudiesCardsAnimation from '../../components/casesStudiesCardsAnimation/CasesStudiesCardsAnimation'

const Home = () => {
    document.title = "Biorce - Home"

  return (
    <div>
        <NewSectionTitle/>

        <BannerHome/>

        <SectionTitle 
            title= "Purpose" 
            icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path className='dark:stroke-[#99ECF3]' d="M5.71837 3.84245C6.02483 3.24709 6.17806 2.94941 6.38391 2.85296C6.56313 2.76899 6.7704 2.76899 6.94962 2.85296C7.15547 2.94941 7.3087 3.24709 7.61516 3.84245L8.40962 5.38585C8.46272 5.489 8.48926 5.54057 8.5236 5.58611C8.55409 5.62654 8.58908 5.66337 8.6279 5.69587C8.67163 5.73249 8.72179 5.76164 8.82209 5.81992L10.413 6.74441C10.9343 7.04732 11.1949 7.19878 11.2826 7.39619C11.359 7.56841 11.359 7.76493 11.2826 7.93714C11.1949 8.13456 10.9343 8.28601 10.413 8.58893L8.82209 9.51341C8.72179 9.5717 8.67163 9.60084 8.6279 9.63746C8.58908 9.66997 8.55409 9.70679 8.5236 9.74722C8.48926 9.79276 8.46272 9.84434 8.40962 9.94749L7.61516 11.4909C7.3087 12.0862 7.15547 12.3839 6.94962 12.4804C6.7704 12.5643 6.56313 12.5643 6.38391 12.4804C6.17806 12.3839 6.02483 12.0862 5.71837 11.4909L4.92391 9.94749C4.87082 9.84434 4.84427 9.79276 4.80993 9.74722C4.77945 9.70679 4.74445 9.66997 4.70563 9.63746C4.6619 9.60084 4.61175 9.5717 4.51144 9.51341L2.92052 8.58893C2.39925 8.28601 2.13861 8.13456 2.05098 7.93714C1.97454 7.76493 1.97454 7.56841 2.05098 7.39619C2.13861 7.19878 2.39925 7.04732 2.92052 6.74441L4.51144 5.81992C4.61175 5.76164 4.6619 5.73249 4.70563 5.69587C4.74445 5.66337 4.77945 5.62654 4.80993 5.58611C4.84427 5.54057 4.87082 5.489 4.92391 5.38585L5.71837 3.84245Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path className='dark:stroke-[#99ECF3]' d="M11.6398 12.9374C11.4706 12.726 11.386 12.6203 11.3543 12.4983C11.3265 12.3911 11.3265 12.2756 11.3543 12.1683C11.386 12.0464 11.4706 11.9407 11.6398 11.7292L12.1835 11.0496C12.3526 10.8381 12.4372 10.7324 12.5348 10.6928C12.6206 10.658 12.713 10.658 12.7988 10.6928C12.8963 10.7324 12.9809 10.8381 13.1501 11.0496L13.6938 11.7292C13.8629 11.9407 13.9475 12.0464 13.9792 12.1683C14.0071 12.2756 14.0071 12.3911 13.9792 12.4983C13.9475 12.6203 13.8629 12.726 13.6938 12.9374L13.1501 13.6171C12.9809 13.8285 12.8963 13.9343 12.7988 13.9739C12.713 14.0087 12.6206 14.0087 12.5348 13.9739C12.4372 13.9343 12.3526 13.8285 12.1835 13.6171L11.6398 12.9374Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path className='dark:stroke-[#99ECF3]' d="M12.1533 2.90831C12.0687 2.82373 12.0264 2.78144 12.0106 2.73267C11.9966 2.68977 11.9966 2.64356 12.0106 2.60067C12.0264 2.5519 12.0687 2.50961 12.1533 2.42502L12.4251 2.15317C12.5097 2.06859 12.552 2.0263 12.6008 2.01045C12.6437 1.99652 12.6899 1.99652 12.7328 2.01045C12.7815 2.0263 12.8238 2.06859 12.9084 2.15317L13.1803 2.42502C13.2648 2.50961 13.3071 2.5519 13.323 2.60067C13.3369 2.64356 13.3369 2.68977 13.323 2.73267C13.3071 2.78144 13.2648 2.82373 13.1803 2.90831L12.9084 3.18016C12.8238 3.26474 12.7815 3.30703 12.7328 3.32288C12.6899 3.33682 12.6437 3.33682 12.6008 3.32288C12.552 3.30703 12.5097 3.26474 12.4251 3.18016L12.1533 2.90831Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />

        <SubBannerText/>

        <SectionTitle 
            title= "Case Studies" 
            icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path className='dark:stroke-[#99ECF3]' d="M9.83317 1.37135V2.13301C9.83317 3.25311 9.83317 3.81317 10.0512 4.24099C10.2429 4.61731 10.5488 4.92327 10.9252 5.11502C11.353 5.33301 11.913 5.33301 13.0332 5.33301H13.7948M9.83317 1.37135C9.57084 1.33301 9.25717 1.33301 8.7325 1.33301H7.43317C5.9397 1.33301 5.19296 1.33301 4.62253 1.62365C4.12076 1.87932 3.71282 2.28727 3.45715 2.78903C3.1665 3.35946 3.1665 4.1062 3.1665 5.59967V10.3997C3.1665 11.8931 3.1665 12.6399 3.45715 13.2103C3.71282 13.7121 4.12076 14.12 4.62253 14.3757C5.19296 14.6663 5.9397 14.6663 7.43317 14.6663H9.5665C11.06 14.6663 11.8067 14.6663 12.3772 14.3757C12.8789 14.12 13.2868 13.7121 13.5425 13.2103C13.8332 12.6399 13.8332 11.8931 13.8332 10.3997V6.43365C13.8332 5.90901 13.8332 5.59537 13.7948 5.33301M9.83317 1.37135C9.89697 1.38068 9.95777 1.39227 10.0178 1.40669C10.2899 1.47201 10.55 1.57975 10.7886 1.72597C11.0577 1.89088 11.2883 2.12148 11.7495 2.58268L12.5835 3.41666C13.0447 3.87784 13.2753 4.10848 13.4402 4.37758C13.5864 4.61617 13.6942 4.87629 13.7595 5.14839C13.7739 5.20843 13.7855 5.2692 13.7948 5.33301M5.83317 11.333H5.83984M8.49984 6.66634C8.07484 7.74401 7.6071 8.22967 6.49984 8.66634C7.6071 9.10301 8.07484 9.58867 8.49984 10.6663C8.92484 9.58867 9.39257 9.10301 10.4998 8.66634C9.39257 8.22967 8.92484 7.74401 8.49984 6.66634Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />

        <CasesStudiesCardsAnimation/>


        <div className='mt-[300px] md:mt-0'>
            <SectionTitle 
                title= "Feedback" 
                icon= {
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path className='dark:stroke-[#99ECF3]' d="M10.2344 14H3.33333C2.59695 14 2 13.403 2 12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333M12 12V10M12 10V8M12 10H10M12 10H14M10 4.66667C10 6.13943 8.80609 7.33333 7.33333 7.33333C5.86057 7.33333 4.66667 6.13943 4.66667 4.66667C4.66667 3.19391 5.86057 2 7.33333 2C8.80609 2 10 3.19391 10 4.66667Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                }
            />

            <Carousel/>
        </div>

        <div className='absolute mt-[-700px] md:mt-10 md:relative w-full'>
            <SectionTitle 
                title= "Partnerships" 
                icon= {
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path className='dark:stroke-[#99ECF3]' d="M10.2344 14H3.33333C2.59695 14 2 13.403 2 12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333M12 12V10M12 10V8M12 10H10M12 10H14M10 4.66667C10 6.13943 8.80609 7.33333 7.33333 7.33333C5.86057 7.33333 4.66667 6.13943 4.66667 4.66667C4.66667 3.19391 5.86057 2 7.33333 2C8.80609 2 10 3.19391 10 4.66667Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                }
            />
                    
            <Partnerships/>
        </div>

        <SectionTitle 
            title= "Newsletter" 
            icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                    <path className='dark:stroke-[#99ECF3]' d="M7.84391 14.0299H6.66683C4.79999 14.0299 3.86657 14.0299 3.15353 13.6666C2.52632 13.3471 2.01639 12.8371 1.69681 12.2099C1.3335 11.4969 1.3335 10.5635 1.3335 8.69661C1.3335 7.36725 1.3335 6.51121 1.46468 5.87101M14.5356 5.87101L10.8635 8.20783C9.82581 8.86818 9.30696 9.19835 8.74935 9.32697C8.25636 9.44068 7.74396 9.44068 7.25098 9.32697C6.69337 9.19835 6.17452 8.86817 5.13682 8.20782L1.46468 5.87101M14.5356 5.87101C14.6668 6.51121 14.6668 7.36725 14.6668 8.69661L14.6668 8.93099M14.5356 5.87101C14.4826 5.61216 14.4081 5.3886 14.3035 5.18331C13.9839 4.55611 13.474 4.04617 12.8468 3.72659C12.1338 3.36328 11.2003 3.36328 9.3335 3.36328H6.66683C4.79999 3.36328 3.86657 3.36328 3.15353 3.72659C2.52632 4.04617 2.01639 4.55611 1.69681 5.18331C1.59221 5.3886 1.51772 5.61216 1.46468 5.87101M10.0002 15.3633H10.0008M12.6668 10.6966C12.2418 11.7743 11.7741 12.2599 10.6668 12.6966C11.7741 13.1333 12.2418 13.619 12.6668 14.6966C13.0918 13.619 13.5596 13.1333 14.6668 12.6966C13.5596 12.2599 13.0918 11.7743 12.6668 10.6966Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />

        <Subscribe/>

    </div>
  )
}

export default Home
