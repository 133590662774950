import { useState, useEffect } from 'react'
import { useTheme } from "../../context/Theme-provider"

const Switcher = () => {
  const { theme, setTheme } = useTheme()
  const [isDarkMode, setIsDarkMode] = useState(theme === "dark")

  useEffect(() => {
    setIsDarkMode(theme === "dark");
  }, [theme]);

  const handleToggle = () => {
    const newTheme = isDarkMode ? "light" : "dark"
    setTheme(newTheme)
    setIsDarkMode(!isDarkMode)
  }

  return (
    <>
      <label className='flex cursor-pointer select-none items-center ml-8'>
        <div className='relative'>
          <input
            type='checkbox'
            checked={isDarkMode}
            onChange={handleToggle}
            className='sr-only'
          />
          <div className="block h-8 w-14 rounded-full bg-white border border-[#00A19A] dark:bg-[#161A1D]"></div>
          <div
            className={`dot absolute top-1 h-6 w-6 rounded-full bg-[#EEE] transition transform p-[3px] dark:bg-[#161A1D] ${isDarkMode ? 'translate-x-7' : 'translate-x-1'}`}
          >
            {isDarkMode ? 
            <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 17 17" fill="none">
                <path d="M14.5 7.97727C13.7324 9.19276 12.3771 10.0001 10.8333 10.0001C8.4401 10.0001 6.5 8.05998 6.5 5.66675C6.5 4.12288 7.30738 2.76758 8.52294 2.00004C8.5153 2.00001 8.50765 2 8.5 2C5.18629 2 2.5 4.68629 2.5 8C2.5 11.3137 5.18629 14 8.5 14C11.8137 14 14.5 11.3137 14.5 8C14.5 7.99242 14.5 7.98485 14.5 7.97727Z" stroke="#99ECF3" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg> :         
            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path d="M8.5013 16.3337V15.667M3.31585 14.1858L3.78726 13.7144M1.16797 9.00033H1.83464M3.31585 3.81488L3.78726 4.28628M8.5013 2.33366V1.66699M13.2153 4.28628L13.6868 3.81488M15.168 9.00033H15.8346M13.2153 13.7144L13.6868 14.1858M12.5013 9.00033C12.5013 11.2095 10.7104 13.0003 8.5013 13.0003C6.29216 13.0003 4.5013 11.2095 4.5013 9.00033C4.5013 6.79119 6.29216 5.00033 8.5013 5.00033C10.7104 5.00033 12.5013 6.79119 12.5013 9.00033Z" stroke="#001A4F" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
            }      
          </div>
        </div>
      </label>
    </>
  )
}

export default Switcher