
import { useState } from 'react'
const AccordionFaqs = (prop:any) => {
    const { number, question, answered } = prop

    const [isOpen, setIsOpen] = useState(false)
    const toggleAccordion = () => {
      setIsOpen(!isOpen)
    }

  return (
    <div className="w-[100%] cursor-pointer">
      <button onClick={toggleAccordion} className="flex w-full py-7 gap-[20px] md:gap-[60px] items-center">
        <div className="font-TTregular font-light text-[40px] md:text-[80px] text-[#001A4F] dark:text-[#99ECF3] leading-[35px] md:leading-[70px]">
            {number}
        </div>
        <div className="font-TTBold text-[20px] md:text-[60px] text-[#001A4F] dark:text-[#99ECF3] leading-[30px] md:leading-[55px] text-justify">
            {question}
        </div>
        <div className="ml-auto">
            {isOpen ? 
            <div className="w-[25px] md:w-[50px] h-[25px] md:h-[50px] rounded-full flex justify-center items-center border border-[#5EBABA80] bg-[linear-gradient(0deg,rgba(165,246,255,0.08)0%,rgba(165,246,255,0.08)100%),rgba(254,254,254,0.80)]
            dark:bg-[rgba(34,39,43,0.8)]">
                <svg  className="w-[15px] md:w-[25px] h-[15px] md:h-[25px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                    <path className='dark:stroke-[#99ECF3]' d="M6.25391 9.84833C7.79661 7.76232 9.59881 5.88876 11.6149 4.27391C11.7879 4.13538 11.9961 4.06612 12.2043 4.06612M18.1547 9.84833C16.612 7.76232 14.8098 5.88876 12.7937 4.27391C12.6208 4.13538 12.4125 4.06612 12.2043 4.06612M12.2043 4.06612V19.9339" stroke="#001A4F" stroke-width="1.98347" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div> :
            <div className="w-[25px] md:w-[50px] h-[25px] md:h-[50px] rounded-full flex justify-center items-center border border-[#5EBABA80] bg-[linear-gradient(0deg,rgba(165,246,255,0.08)0%,rgba(165,246,255,0.08)100%),rgba(254,254,254,0.80)]
            dark:bg-[rgba(34,39,43,0.8)]">
                <svg  className="opacity-50 w-[15px] md:w-[25px] h-[15px] md:h-[25px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25 24" fill="none">
                    <path className='dark:stroke-[#99ECF3]' d="M18.1563 14.1524C16.6135 16.2384 14.8113 18.1119 12.7952 19.7268C12.6223 19.8653 12.4141 19.9346 12.2058 19.9346M6.25542 14.1524C7.79813 16.2384 9.60033 18.1119 11.6164 19.7268C11.7894 19.8653 11.9976 19.9346 12.2058 19.9346M12.2058 19.9346L12.2058 4.0668" stroke="#001A4F" stroke-width="1.98347" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            }
        </div>
      </button>
        <div className={`flex items-center justify-center transition-all duration-1000 ${isOpen ? 'h-[250px] lg:h-[170px]' : 'h-[0px] overflow-hidden'}`} >
            <div className={`text-[#001A4F] dark:text-[#99ECF3] text-justify text-[12px] md:text-[22px] leading-[18px] md:leading-[25px] pb-5 w-[78%] transition-all duration-1000 overflow-hidden ${isOpen ? 'h-[250px] lg:h-[170px]' : 'h-[0px]'}`}>
                {answered}
            </div>
        </div>
      <div className="w-[100%] h-[2px] bg-[#001A4F] dark:bg-[#99ECF3]"></div>

    </div>
  )
}

export default AccordionFaqs
