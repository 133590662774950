import ShineBorder from "../magicui/shine-border"
import "./SectionTitle.css"

const SectionTitle = (prop:any) => {
    const title = prop.title 
    const icon = prop.icon
  return (
    <div className="my-[10px] lg:my-[30px] mx-auto flex items-center justify-center">
        <div>
            <ShineBorder className="sectionTitleButtonBorder text-center dark:text-[#99ECF3] dark:bg-[#161A1D]" borderRadius= {15} color={["#5EBABA", "#A5F6FF", "#FEFEFE"]}>
                <div className="flex items-center text-xs rounded-[13px]">
                    <div className="text-[#001A4F] font-inter text-sm font-medium leading-[18px] mr-2">{icon}</div>
                    <h2 className="font-TTBold lg:font-TTregular">{title}</h2>                    
                </div>
            </ShineBorder>
        </div>
    </div>
  )
}

export default SectionTitle