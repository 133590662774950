import { useRef, useEffect, useState } from 'react'
import "./AnimationJarvisButton.css"

const AnimationJarvisButton = () => {
    const redDivRef = useRef<HTMLDivElement>(null)
    const greenDivRef = useRef<HTMLDivElement>(null)
    const videoRef = useRef<HTMLDivElement>(null);

    const [stage, setStage] = useState<'stage1' | 'stage2' | 'stage3'>('stage1')
    const [opacityStage, setOpacityStage] = useState<'opacityEffect' | 'opacityEffectOff'>('opacityEffectOff')
    const [jVideo, setjVideo] = useState<'/IndustryCaseStudyLight.mp4' | '/PatientCaseStudyLight.mp4' | '/MedicalCaseStudyLight.mp4'>('/IndustryCaseStudyLight.mp4')
    const [jVideoD, setjVideoD] = useState<'/IndustryCaseStudyDark.mp4'| '/PatientCaseStudyDark.mp4'| '/MedicalCaseStudyDark.mp4'>('/IndustryCaseStudyDark.mp4')
    const [jVideoCell, setjVideoCell] = useState<'/IndustrialCaseStudyLigthMobile.mp4' | '/PatientCaseStudyLightMobile.mp4' | '/MedicalCaseStudyLightMobile.mp4'>('/IndustrialCaseStudyLigthMobile.mp4')
    const [jVideoCellD, setjVideoCellD] = useState<'/IndustrialCaseStudyDarkMobile.mp4'| '/PatientCaseStudyDarkMobile.mp4'| '/MedicalCaseStudyDarkMobile.mp4'>('/IndustrialCaseStudyDarkMobile.mp4')
    const [jVideoTitle, setjVideoTitle] = useState<'Industry' | 'Patients' | 'Psysicians'>('Industry')
    const [isVideoFullScreen, setIsVideoFullScreen] = useState(false);
    const [fullScreenBTN, setFullScreenBTN] = useState<'Full Screen' | 'Exit Screen' >('Full Screen')

    const checkStage = () => {
        if (!redDivRef.current || !greenDivRef.current) return

        const redDiv = redDivRef.current.getBoundingClientRect()
        const greenDiv = greenDivRef.current.getBoundingClientRect()

        const stage1End = greenDiv.top + greenDiv.height / 3
        const stage2End = greenDiv.top + (2 * greenDiv.height) / 3
        const stage3End = greenDiv.top + (3 * greenDiv.height) / 3
        
        if (redDiv.top < stage1End ) {
            setStage('stage1')
            setjVideoTitle('Industry')
            setjVideo('/IndustryCaseStudyLight.mp4')
            setjVideoD('/IndustryCaseStudyDark.mp4')
            setjVideoCell('/IndustrialCaseStudyLigthMobile.mp4')
            setjVideoCellD('/IndustrialCaseStudyDarkMobile.mp4')
        } else if (redDiv.top < stage2End ) {
            setStage('stage2')
            setjVideoTitle('Patients')
            setjVideo('/PatientCaseStudyLight.mp4')
            setjVideoD('/PatientCaseStudyDark.mp4')
            setjVideoCell('/PatientCaseStudyLightMobile.mp4')
            setjVideoCellD('/PatientCaseStudyDarkMobile.mp4')
        } else if (redDiv.top < stage3End ){
            setStage('stage3')
            setjVideoTitle('Psysicians')
            setjVideo('/MedicalCaseStudyLight.mp4')
            setjVideoD('/MedicalCaseStudyDark.mp4')
            setjVideoCell('/MedicalCaseStudyLightMobile.mp4')
            setjVideoCellD('/MedicalCaseStudyDarkMobile.mp4')
        }
    }

    const handleOpacityChange = () => {
        setOpacityStage('opacityEffectOff');
        setTimeout(() => {
            setOpacityStage('opacityEffect');
        }, 500);
    }

    useEffect(() => {
        window.addEventListener('scroll', checkStage)
        return () => {
        window.removeEventListener('scroll', checkStage)
        }
    }, [])

    useEffect(() => {
        handleOpacityChange();
    }, [stage])

    const scrollToSection = (id: any) => {
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const toggleFullScreen = () => {

        if (videoRef.current) {
            if(!isVideoFullScreen){
                videoRef.current.classList.remove('lg:w-[1100px]')
                videoRef.current.classList.remove('lg:mt-[-85px]')
                videoRef.current.classList.remove('md:w-[800px]')
                videoRef.current.classList.remove('lg:ml-0')
                
                videoRef.current.classList.add('lg:w-[990px]')
                videoRef.current.classList.add('lg:mt-[-155px]')
                videoRef.current.classList.add('md:w-[5000px]')
                videoRef.current.classList.add('lg:ml-[-490px]')
                setIsVideoFullScreen(true)
                setFullScreenBTN("Exit Screen")
            }else{
                videoRef.current.classList.remove('lg:w-[990px]')
                videoRef.current.classList.remove('lg:mt-[-155px]')
                videoRef.current.classList.remove('md:w-[5000px]')
                videoRef.current.classList.remove('lg:ml-[-490px]')

                videoRef.current.classList.add('lg:w-[1100px]')
                videoRef.current.classList.add('md:w-[800px]')
                videoRef.current.classList.add('lg:mt-[-85px]')
                videoRef.current.classList.add('lg:ml-0')
                setIsVideoFullScreen(false)
                setFullScreenBTN("Full Screen")
            }
          }
    }

    //className="w-[430px] md:w-[800px] lg:w-[1100px] h-[600px] relative mt-[-250px] md:mt-[0px] lg:mt-[-85px] scale-75 md:scale-100 lg:scale-95 ml-0 md:ml-[-30px] lg:ml-0 transition-all duration-700"

  return (
    <div className="relative max-w-7xl xl:mx-auto">
        <div className="h-[6000px] md:h-[4000px] lg:h-[3000px]" ref={greenDivRef}>
            <div className='w-4 h-4 absolute mt-[1000px] lg:mt-0' id='stageArea1'></div>
            <div className='w-4 h-4 mt-[2000px] md:mt-[1000px] lg:mt-[1000px] absolute' id='stageArea2'></div>
            <div className='w-4 h-4 mt-[3000px] lg:mt-[2000px] absolute' id='stageArea3'></div>

            <div className="h-[1000px] md:h-[500px] lg:h-[600px] sticky mt-[-100px] md:mt-[-20px] lg:mt-[-100px] top-[-130px] md:top-10 lg:top-40 block md:flex lg:flex" ref={redDivRef}>
                <div className={` w-full lg:w-1/2 mt-0 lg:mt-[0px] h-[600px] pl-0 scale-50 lg:scale-100 transitionAnimationTransform ${stage}`}>

                    <button 
                    className="absolute transitionAnimationTransform1"
                    onClick={() => scrollToSection('stageArea2')}
                    >
                        <div className="w-[170px] h-[170px] rounded-full bg-gradient-to-b from-[#5EBABA] to-[#A5F6FF] blur-[10px]">
                        </div>
                        <svg className="absolute" xmlns="http://www.w3.org/2000/svg" width="76" height="75" viewBox="0 0 76 75" fill="none">
                            <path d="M62.7473 25.3454H64.2768C65.7378 25.3454 66.4683 25.3454 67.0755 25.4679C69.523 25.9616 71.436 27.8745 71.9297 30.3221C72.0522 30.9292 72.0522 31.6598 72.0522 33.1208C72.0522 34.4341 72.0522 35.0908 71.9711 35.7011C71.6489 38.1252 70.3854 40.3251 68.4538 41.8248C67.9675 42.2024 67.4003 42.5333 66.2658 43.195L62.6956 45.2777M25.5278 31.5487H50.3408M29.6633 43.9552H46.2053M62.6956 45.2777C62.7473 44.5833 62.7473 43.7247 62.7473 42.4416V26.321C62.7473 23.9667 62.7473 22.7896 62.3965 21.7913C61.9424 20.499 61.0734 19.3934 59.925 18.6468C59.0378 18.0701 57.894 17.792 55.6064 17.2358C49.6944 15.7983 46.7383 15.0796 43.7533 14.7271C39.8874 14.2707 35.9812 14.2707 32.1153 14.7271C29.1303 15.0796 26.1742 15.7983 20.2622 17.2358C17.9746 17.792 16.8308 18.0701 15.9436 18.6468C14.7952 19.3934 13.9262 20.499 13.4721 21.7913C13.1735 22.6411 13.129 23.6206 13.1224 25.3454M62.6956 45.2777C62.6691 45.6329 62.6291 45.9451 62.5686 46.2492C62.2029 48.0883 61.2901 49.7739 59.9499 51.0853C59.2951 51.726 58.483 52.256 56.859 53.3159C51.6923 56.6878 49.1089 58.3737 46.4315 59.3496C40.9433 61.35 34.9253 61.35 29.4371 59.3496C26.7597 58.3737 24.1763 56.6878 19.0096 53.3159C17.3856 52.256 16.5735 51.726 15.9187 51.0853C14.5784 49.7739 13.6657 48.0883 13.3 46.2492C13.2395 45.9451 13.1995 45.6329 13.173 45.2777M13.173 45.2777C13.1213 44.5833 13.1213 43.7247 13.1213 42.4416V26.321C13.1213 25.9708 13.1213 25.6467 13.1224 25.3454M13.173 45.2777L9.60277 43.195C8.4683 42.5333 7.90107 42.2024 7.41481 41.8248C5.48322 40.3251 4.21969 38.1252 3.89751 35.7011C3.81641 35.0908 3.81641 34.4341 3.81641 33.1208C3.81641 31.6598 3.81641 30.9292 3.93888 30.3221C4.43259 27.8745 6.34555 25.9616 8.79307 25.4679C9.40025 25.3454 10.1308 25.3454 11.5918 25.3454H13.1224" stroke="url(#paint0_linear_3104_2351)" stroke-width="6.2601" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                                <linearGradient id="paint0_linear_3104_2351" x1="37.9343" y1="14.3848" x2="37.9343" y2="60.8499" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#001A4F"/>
                                <stop offset="0.25" stop-color="#001A4F" stop-opacity="0.83"/>
                                <stop offset="1" stop-color="#001A4F" stop-opacity="0.35"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </button>

                    <div className="absolute transitionAnimationTransform2">
                        <div className="w-[70px] h-[70px] rounded-full bg-gradient-to-b from-[#5EBABA] to-[#A5F6FF] blur-[15px]">
                        </div>
                    </div>

                    <button 
                    className="absolute transitionAnimationTransform3"
                    onClick={() => scrollToSection('stageArea1')}
                    >
                        <div className="w-[261px] h-[260px] rounded-full bg-gradient-to-b from-[#5EBABA] to-[#A5F6FF] blur-[10px]">
                        </div>
                        <svg className="absolute " xmlns="http://www.w3.org/2000/svg" width="76" height="75" viewBox="0 0 76 75" fill="none">
                            <path d="M26.2784 25.9911L13.6716 38.5979C7.31031 44.9592 7.31031 55.2729 13.6716 61.6343C20.0329 67.9956 30.3467 67.9956 36.708 61.6342L49.3148 49.0274M26.2784 25.9911L38.8852 13.3843C45.2466 7.02295 55.5603 7.02295 61.9216 13.3843C68.2829 19.7456 68.2829 30.0593 61.9216 36.4206L49.3148 49.0274M26.2784 25.9911L49.3148 49.0274M42.1645 26.5613L47.6475 21.0784C49.0799 19.646 51.2139 19.3429 52.9432 20.169" stroke="url(#paint0_linear_3104_2346)" stroke-width="6.2601" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                                <linearGradient id="paint0_linear_3104_2346" x1="37.7966" y1="8.61328" x2="37.7966" y2="66.4052" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#001A4F"/>
                                <stop offset="0.25" stop-color="#001A4F" stop-opacity="0.83"/>
                                <stop offset="1" stop-color="#001A4F" stop-opacity="0.35"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </button>

                    <div className="absolute transitionAnimationTransform4">
                        <div className="w-[90px] h-[90px] rounded-full bg-gradient-to-b from-[#5EBABA] to-[#A5F6FF] blur-[15px]">
                        </div>
                    </div>

                    <button 
                    className="absolute transitionAnimationTransform5"
                    onClick={() => scrollToSection('stageArea3')}
                    >
                        <div className="w-[190px] h-[190px] rounded-full bg-gradient-to-b from-[#5EBABA] to-[#A5F6FF] blur-[10px]">
                        </div>
                        <svg className="absolute" xmlns="http://www.w3.org/2000/svg" width="76" height="76" viewBox="0 0 76 76" fill="none">
                            <path d="M33.3953 10.1426C37.6777 10.1426 41.1493 13.6142 41.1493 17.8966V23.1209C41.1493 28.7637 39.171 34.228 35.5585 38.5629L34.7963 39.4776C32.4141 42.3363 29.0276 43.7656 25.6412 43.7656M17.8871 10.1426C13.6047 10.1426 10.1331 13.6142 10.1331 17.8966V23.1209C10.1331 28.7637 12.1114 34.228 15.7238 38.5629L16.4861 39.4776C18.8683 42.3363 22.2547 43.7656 25.6412 43.7656M59.7591 44.2605C64.0415 44.2605 67.5131 40.7889 67.5131 36.5064C67.5131 32.2239 64.0415 28.7523 59.7591 28.7523C55.4766 28.7523 52.005 32.2239 52.005 36.5064C52.005 40.7889 55.4766 44.2605 59.7591 44.2605ZM59.7591 44.2605V48.9129C59.7591 58.3343 52.1215 65.9718 42.7001 65.9718C33.2787 65.9718 25.6412 58.3343 25.6412 48.9129V43.7656" stroke="url(#paint0_linear_3104_2356)" stroke-width="6.49864" stroke-linecap="round" stroke-linejoin="round"/>
                            <defs>
                                <linearGradient id="paint0_linear_3104_2356" x1="38.8231" y1="10.1426" x2="38.8231" y2="65.9718" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#001A4F"/>
                                <stop offset="0.25" stop-color="#001A4F" stop-opacity="0.83"/>
                                <stop offset="1" stop-color="#001A4F" stop-opacity="0.35"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    </button>

                    <div className="absolute transitionAnimationTransform6">
                        <div className="w-[120px] h-[120px] rounded-full bg-gradient-to-b from-[#5EBABA] to-[#A5F6FF] blur-[15px]">
                        </div>
                    </div>

                </div>
                <div ref={videoRef} className="w-[430px] md:w-[800px] lg:w-[1100px] h-[600px] relative mt-[-250px] md:mt-[0px] lg:mt-[-85px] scale-75 md:scale-100 lg:scale-95 ml-0 md:ml-[-30px] lg:ml-0 transition-all duration-700">
                    <h3 className='text-[#001A4F] font-tt-norms-pro text-4xl font-normal leading-[75px] text-center mb-2 dark:text-[#99ECF3]'>Case Study: <strong className='font-TTBold'> {jVideoTitle} </strong></h3>
                    <div className="rounded-[24px] shadow-[0px_0px_0px_13.385px_rgba(81,81,81,0.12),0px_10.708px_32.124px_0px_rgba(34,39,43,0.32)] mr-5">

                        <video className={`w-full h-full object-cover rounded-[24px] hidden md:block lg:block dark:hidden ${opacityStage}`} src={jVideo} playsInline autoPlay loop muted controls={false}></video>

                        <video className={`w-full h-full object-cover rounded-[24px] block md:hidden lg:hidden dark:hidden  ${opacityStage}`} src={jVideoCell} playsInline autoPlay loop muted controls={false}></video>
                        
                        <video className={`w-full h-full object-cover rounded-[24px] hidden dark:md:block dark:lg:block ${opacityStage}`} src={jVideoD} playsInline autoPlay loop muted controls={false}></video>

                        <video className={`w-full h-full object-cover rounded-[24px] hidden dark:block dark:md:hidden lg:dark:hidden ${opacityStage}`} src={jVideoCellD} playsInline autoPlay loop muted controls={false}></video>

                    </div>
                    <div className={`flex w-full h-[30px] mt-[18px] `}>


                        {/* <button className='flex items-center content-center rounded-[50%] p-2 w-[31px] hover:w-[140px] transition-all duration-300  hover:rounded-[10px] text-[0px] hover:text-[18px] border border-[rgba(94,186,186,0.5)] backdrop-blur-[5px] bg-transparent'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                    <path className='dark:stroke-[#99ECF3]' d="M8 5H9M8 7H9M4 9H9M4 11H9M11 5H13.25C13.6642 5 14 5.33579 14 5.75V12C14 12.8284 13.3284 13.5 12.5 13.5M11 5V12C11 12.8284 11.6716 13.5 12.5 13.5M11 5V3.25C11 2.83579 10.6642 2.5 10.25 2.5H2.75C2.33579 2.5 2 2.83579 2 3.25V12C2 12.8284 2.67157 13.5 3.5 13.5H12.5M4 5H6V7H4V5Z" stroke="#001A4F"  stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>  
                            <p className='text-[#001A4F] dark:text-[#99ECF3]'>
                                Read more
                            </p>
                        </button> */}

                        <button className='hidden md:flex items-center content-center rounded-[50%] p-2 w-[31px] hover:w-[140px] transition-all duration-300 hover:rounded-[10px] text-[0px] hover:text-[18px] border border-[rgba(94,186,186,0.5)] backdrop-blur-[5px] bg-[linear-gradient(0deg,rgba(165,246,255,0.08)0%,rgba(165,246,255,0.08)100%),rgba(254,254,254,0.80)]
                        ml-4 cursor-pointer overflow-hidden' onClick={toggleFullScreen}>
                            {isVideoFullScreen ? 
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path className='dark:stroke-[#99ECF3]' d="M2.66666 9.94814C3.72062 9.77165 4.78573 9.73162 5.83536 9.82873C5.9254 9.83705 6.00505 9.87585 6.06427 9.93507C6.12349 9.99429 6.16228 10.0739 6.17061 10.164C6.26772 11.2136 6.22769 12.2787 6.0512 13.3327M13.3333 9.94814C12.2794 9.77165 11.2143 9.73162 10.1646 9.82873C10.0746 9.83705 9.99493 9.87585 9.93571 9.93507C9.87649 9.99429 9.8377 10.0739 9.82937 10.164C9.73226 11.2136 9.77229 12.2787 9.94878 13.3327M6.0512 2.66602C6.22769 3.71998 6.26771 4.78509 6.17061 5.83472C6.16228 5.92476 6.12349 6.00441 6.06427 6.06363C6.00505 6.12285 5.9254 6.16164 5.83536 6.16997C4.78573 6.26707 3.72062 6.22705 2.66666 6.05056M13.3333 6.05056C12.2794 6.22705 11.2143 6.26707 10.1646 6.16997C10.0746 6.16164 9.99493 6.12285 9.93571 6.06363C9.87649 6.00441 9.8377 5.92476 9.82937 5.83472C9.73226 4.78509 9.77229 3.71998 9.94878 2.66602" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                             : 
                             <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path className='dark:stroke-[#99ECF3]' d="M6.22218 13.1617C5.16822 13.3382 4.10311 13.3782 3.05347 13.2811C2.96343 13.2728 2.88379 13.234 2.82457 13.1747C2.76535 13.1155 2.72655 13.0359 2.71823 12.9458C2.62112 11.8962 2.66115 10.8311 2.83764 9.77713M9.77774 13.1617C10.8317 13.3382 11.8968 13.3782 12.9464 13.2811C13.0365 13.2728 13.1161 13.234 13.1754 13.1747C13.2346 13.1155 13.2734 13.0359 13.2817 12.9458C13.3788 11.8962 13.3388 10.8311 13.1623 9.77713M2.83764 6.22157C2.66115 5.16761 2.62112 4.1025 2.71822 3.05286C2.72655 2.96282 2.76535 2.88318 2.82457 2.82396C2.88379 2.76474 2.96343 2.72594 3.05347 2.71761C4.10311 2.62051 5.16822 2.66054 6.22218 2.83703M9.77774 2.83703C10.8317 2.66054 11.8968 2.62051 12.9464 2.71761C13.0365 2.72594 13.1161 2.76474 13.1754 2.82396C13.2346 2.88318 13.2734 2.96282 13.2817 3.05286C13.3788 4.1025 13.3388 5.16761 13.1623 6.22157" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            
                            }
                            
                            <p className={`ml-[2px] text-[#001A4F] dark:text-[#99ECF3] overflow-hidden`}>
                                {fullScreenBTN}
                            </p> 
                        </button>
                        
                        <div className='hidden lg:flex items-center ml-[30%]'>
                            <button
                                className={`my-[4px] mx-[8px] rounded-[50%] h-[20px] w-[20px] ${stage === 'stage1' ? 'bg-[#454F59]' : 'bg-[#B4B9C9]'}`}
                                onClick={() => scrollToSection('stageArea1')}
                            >
                            </button>
                            <button
                                className={`my-[4px] mx-[8px] rounded-[50%] h-[20px] w-[20px] ${stage === 'stage2' ? 'bg-[#454F59]' : 'bg-[#B4B9C9]'}`}
                                onClick={() => scrollToSection('stageArea2')}
                            >
                            </button>
                            <button  
                                className={`my-[4px] mx-[8px] rounded-[50%] h-[20px] w-[20px] ${stage === 'stage3' ? 'bg-[#454F59]' : 'bg-[#B4B9C9]'}`}
                                onClick={() => scrollToSection('stageArea3')}
                            >
                            </button>
                        </div>
                    </div>
                </div>
            </div>      
        </div>
    </div>
  )
}

export default AnimationJarvisButton
