

import { useContext, useEffect, useRef, useState } from 'react'
import { TransitionGroup, CSSTransition } from 'react-transition-group'
import { useLocation } from 'react-router-dom'
import gsap from 'gsap'
import TransitionContext from '../../context/TransitionContext'

const TransitionComponent = ({ direction, children, isNavigating, isIntermediate  }: any) => {
  const [changeDirection, setChangeDirection] = useState('')
  const previousDirectionRef = useRef('/home')
  const initialXPercentRef = useRef(100)
  const finalXPercentRef = useRef(-100)
  const routesDirection = [
    "/home", "/about", "/jarvis", "/careers", "/contact", "/terms-of-use", "/privacy-Policy", "/faqs"
  ]
  
  useEffect(() => {
    if (isNavigating === true) return;
    previousDirectionRef.current = changeDirection
    
    setChangeDirection(direction) 
    
    if (routesDirection.indexOf(direction) > routesDirection.indexOf(changeDirection)) {
      initialXPercentRef.current = 100;
      finalXPercentRef.current = -100;
    } 
     else if (routesDirection.indexOf(direction) < routesDirection.indexOf(changeDirection)) {
       initialXPercentRef.current = -100;
       finalXPercentRef.current = 100;
     }

  }, [direction])

  const location = useLocation()
  const { toggleCompleted }:any = useContext(TransitionContext)

  const handleEnter = (node:any) => {  
    toggleCompleted(false)
    const initialXPercent = initialXPercentRef.current
    gsap.set(node, { autoAlpha: 0, scale: 0.95, xPercent: initialXPercent })
    gsap.timeline({
      paused: true,
      onComplete: () => toggleCompleted(true),
    })
    .to(node, { autoAlpha: 1, xPercent: 0, duration: 0.2 })
    .to(node, { scale: 1, duration: 1 })
    .play()
  }

  const handleExit = (node:any) => {
    const finalXPercent = finalXPercentRef.current    
    gsap
      .timeline({ paused: true })
      .to(node, { scale: 0.95, duration: 0 })
      .to(node, { xPercent: finalXPercent, autoAlpha: 1, duration: 0.2 })
      .play()
  }

  return (
    <TransitionGroup>
      <CSSTransition
        key={location.pathname}
        timeout={200}
        classNames="fade"
        onEnter={ handleEnter}   
        onEntering={isIntermediate ? handleExit : undefined}
      >
        { children }
      </CSSTransition>
    </TransitionGroup>
  )
}

export default TransitionComponent