

const Partnerships = () => {
  return (

    <div className="flex mx-10 lg:mx-20  mb-[50px] lg:mb-[150px] max-w-7xl mt-10 2xl:mx-auto">
        <div className="h-[40px] md:h-[100px] lg:h-[140px] flex p-2 lg:py-[48px] lg:px-[40px] flex-col justify-center items-start gap-[24px] self-stretch rounded-[8px] w-full border-[0.66px] border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0)_100%)] backdrop-blur-[12px]
        dark:bg-[#161A1D] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgb(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)] dark:backdrop-blur-[10px]">
            <div className="flex justify-center items-center gap-2 lg:gap-[72px] self-stretch">

                <svg className="w-[80px] lg:w-[175px] h-[20px] md:h-[40px] lg:h-[58px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 177 58" fill="#B4B9C9">
                    <path className="dark:fill-[#454F59]" fill-rule="evenodd" clip-rule="evenodd" d="M43.771 20.3759H23.2377V26.4689H37.8009C37.0812 35.0133 29.9721 38.6572 23.2625 38.6572C14.6775 38.6572 7.18628 31.9028 7.18628 22.4353C7.18628 13.2125 14.3277 6.11028 23.281 6.11028C30.1894 6.11028 34.2601 10.5138 34.2601 10.5138L38.5254 6.09585C38.5254 6.09585 33.0503 3.30144e-08 23.0631 3.30144e-08C10.3462 -0.000687365 0.506836 10.733 0.506836 22.3267C0.506836 33.6866 9.76127 44.7647 23.3876 44.7647C35.371 44.764 44.1429 36.5551 44.1429 24.4157C44.1429 21.8544 43.771 20.3745 43.771 20.3745V20.3759Z" fill="#B4B9C9"/>
                    <path className="dark:fill-[#454F59]" fill-rule="evenodd" clip-rule="evenodd" d="M60.6753 21.6159C64.819 21.6159 68.7454 24.9656 68.7454 30.3631C68.7454 35.6471 64.8355 39.0917 60.6568 39.0917C56.065 39.0917 52.4417 35.4148 52.4417 30.3218C52.4417 25.3375 56.0189 21.6159 60.6753 21.6159ZM60.5915 15.9648C52.166 15.9648 46.1279 22.5515 46.1279 30.2345C46.1279 38.0296 51.9852 44.7393 60.6905 44.7393C68.5715 44.7393 75.0289 38.7157 75.0289 30.4016C75.0289 20.8722 67.5177 15.9648 60.5915 15.9648Z" fill="#B4B9C9"/>
                    <path className="dark:fill-[#454F59]" fill-rule="evenodd" clip-rule="evenodd" d="M92.1312 21.6159C96.2755 21.6159 100.202 24.9656 100.202 30.3631C100.202 35.6471 96.2913 39.0917 92.1126 39.0917C87.5215 39.0917 83.8989 35.4148 83.8989 30.3218C83.8989 25.3375 87.4761 21.6159 92.1312 21.6159ZM92.048 15.9648C83.6233 15.9648 77.5845 22.5515 77.5845 30.2345C77.5845 38.0296 83.4411 44.7393 92.147 44.7393C100.028 44.7393 106.485 38.7157 106.485 30.4016C106.485 20.8722 98.9742 15.9648 92.048 15.9648Z" fill="#B4B9C9"/>
                    <path className="dark:fill-[#454F59]" fill-rule="evenodd" clip-rule="evenodd" d="M123.462 21.6186C127.254 21.6186 131.147 24.8556 131.147 30.3843C131.147 36.0045 127.261 39.1019 123.378 39.1019C119.256 39.1019 115.42 35.7543 115.42 30.4379C115.42 24.914 119.404 21.6186 123.462 21.6186ZM122.902 15.9785C115.169 15.9785 109.089 22.7521 109.089 30.3548C109.089 39.0139 116.137 44.7571 122.769 44.7571C126.87 44.7571 129.05 43.1294 130.661 41.2617V44.0979C130.661 49.0623 127.646 52.0353 123.096 52.0353C118.702 52.0346 116.498 48.7674 115.732 46.9128L110.202 49.2245C112.164 53.3716 116.113 57.6974 123.143 57.6974C130.832 57.6974 136.692 52.854 136.692 42.697V16.8433H130.661V19.2808C128.806 17.2818 126.269 15.9785 122.902 15.9785Z" fill="#B4B9C9"/>
                    <path className="dark:fill-[#454F59]" fill-rule="evenodd" clip-rule="evenodd" d="M163.765 21.4776C166.394 21.4776 168.285 22.8744 169.089 24.5503L156.206 29.9353C155.65 25.7663 159.6 21.4776 163.765 21.4776ZM163.513 15.9434C156.218 15.9434 150.093 21.7477 150.093 30.312C150.093 39.3747 156.92 44.7495 164.214 44.7495C170.301 44.7495 174.037 41.419 176.268 38.435L171.293 35.1259C170.003 37.129 167.845 39.086 164.245 39.086C160.201 39.086 158.341 36.8712 157.188 34.7265L176.48 26.7211L175.479 24.375C173.615 19.7811 169.267 15.944 163.514 15.944L163.513 15.9434Z" fill="#B4B9C9"/>
                    <path className="dark:fill-[#454F59]" fill-rule="evenodd" clip-rule="evenodd" d="M140.896 43.8959H147.233V1.48828H140.896V43.8959Z" fill="#B4B9C9"/>
                </svg>

                <div className="flex w-[130px] lg:w-[279px] justify-center items-center gap-[0px] md:gap-[7px] lg:gap-[13px]">

                    <svg className="w-[80px] md:w-[40px] lg:w-[175px] h-[20px] md:h-[40px] lg:h-[58px]" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79 52" fill="#B4B9C9">
                        <path className="dark:fill-[#454F59]" d="M29.4782 15.4722V10.8349C29.9362 10.8062 30.3942 10.7776 30.8522 10.7776C43.5619 10.3769 51.892 21.7126 51.892 21.7126C51.892 21.7126 42.9036 34.1933 33.2567 34.1933C31.9686 34.1933 30.7091 33.9929 29.5068 33.5922V19.5084C34.459 20.1095 35.4609 22.2851 38.4093 27.2373L45.0218 21.684C45.0218 21.684 40.1841 15.3577 32.0545 15.3577C31.1957 15.3291 30.3369 15.3863 29.4782 15.4722ZM29.4782 0.128906V7.05629L30.8522 6.97041C48.5142 6.36928 60.0503 21.4549 60.0503 21.4549C60.0503 21.4549 46.8253 37.5425 33.0564 37.5425C31.8541 37.5425 30.6804 37.428 29.5068 37.2276V41.5215C30.4801 41.636 31.482 41.7218 32.4552 41.7218C45.2795 41.7218 54.5542 35.1666 63.5426 27.4377C65.0311 28.64 71.1284 31.5311 72.3879 32.7907C63.8575 39.9471 43.9627 45.7008 32.6842 45.7008C31.5965 45.7008 30.5659 45.6435 29.5354 45.529V51.569H78.2561V0.128906L29.4782 0.128906ZM29.4782 33.5922V37.2563C17.6272 35.138 14.3352 22.8003 14.3352 22.8003C14.3352 22.8003 20.0317 16.5027 29.4782 15.4722V19.4798H29.4495C24.4973 18.8786 20.6042 23.516 20.6042 23.516C20.6042 23.516 22.8084 31.3308 29.4782 33.5922ZM8.43837 22.2851C8.43837 22.2851 15.4516 11.9226 29.5068 10.8349V7.05629C13.9345 8.31582 0.480469 21.4836 0.480469 21.4836C0.480469 21.4836 8.09487 43.5253 29.4782 45.529V41.5215C13.7914 39.5749 8.43837 22.2851 8.43837 22.2851Z" fill="#B4B9C9"/>
                    </svg>
                    
                    <svg className="w-[80px] lg:w-[175px] h-[20px] md:h-[40px] lg:h-[58px]" xmlns="http://www.w3.org/2000/svg" width="189" height="36" viewBox="0 0 189 36" fill="none">
                        <path className="dark:fill-[#454F59]" d="M184.887 32.3787V31.6058H185.374C185.631 31.6058 186.004 31.6344 186.004 31.9493C186.004 32.2642 185.803 32.3787 185.488 32.3787H184.887ZM184.887 32.9226H185.231L186.004 34.268H186.834L185.975 32.8653C186.404 32.8939 186.748 32.5791 186.776 32.1497V32.0352C186.776 31.2909 186.261 31.0619 185.402 31.0619H184.171V34.268H184.887V32.9226ZM188.494 32.6649C188.494 30.7756 187.034 29.6879 185.402 29.6879C183.771 29.6879 182.311 30.7756 182.311 32.6649C182.311 34.5542 183.771 35.642 185.402 35.642C187.034 35.642 188.494 34.5542 188.494 32.6649ZM187.578 32.6649C187.635 33.8672 186.691 34.8977 185.488 34.955H185.402C184.143 35.0122 183.084 34.0103 183.026 32.7508C182.969 31.4913 183.971 30.4321 185.231 30.3749C186.49 30.3176 187.549 31.3195 187.607 32.5791C187.578 32.6077 187.578 32.6363 187.578 32.6649ZM74.9936 0.861939V34.6401H84.5259V0.861939H74.9936ZM-0.00537109 0.804688V34.6115H9.61281V8.36184L17.1127 8.39046C19.5745 8.39046 21.292 8.9916 22.4657 10.2511C23.9828 11.8542 24.584 14.4591 24.584 19.1823V34.6115H33.9159V15.9476C33.9159 2.6081 25.4141 0.804688 17.1127 0.804688H-0.00537109ZM90.3655 0.861939V34.6401H105.823C114.067 34.6401 116.758 33.2661 119.649 30.2031C121.711 28.0562 123.027 23.3044 123.027 18.1231C123.027 13.3713 121.911 9.13472 119.936 6.50117C116.443 1.77796 111.348 0.861939 103.734 0.861939H90.3655ZM99.812 8.19008H103.905C109.86 8.19008 113.695 10.8523 113.695 17.7796C113.695 24.707 109.86 27.3978 103.905 27.3978H99.812V8.19008ZM61.2533 0.861939L53.2954 27.6268L45.681 0.861939H35.3758L46.2535 34.6401H59.9938L70.986 0.861939H61.2533ZM127.493 34.6401H137.025V0.861939H127.493V34.6401ZM154.229 0.861939L140.918 34.6115H150.307L152.426 28.6287H168.17L170.174 34.5828H180.393L166.968 0.861939H154.229ZM160.412 7.01643L166.195 22.8177H154.458L160.412 7.01643Z" fill="#B4B9C9"/>
                    </svg>

                
                </div>
            </div>
        </div>
    </div>

  )
}

export default Partnerships
