import { Link } from "react-router-dom"
import ShineBorder from "../magicui/shine-border"

const NewSectionTitle = () => {
  return (
    <div className="flex justify-center items-center my-5">
        <Link to="/jarvis">
            <ShineBorder className="flex w-[320px] h-[30px] p-[2px] justify-center items-start gap-[8px] flex-shrink-0 dark:bg-[#161A1D] lg:h-[37px] lg:w-[390px] lg:p-[6px]" borderRadius= {18} color={["#5EBABA", "#A5F6FF", "#FEFEFE"]}>
                <div className="flex">
                    <div className="flex items-center gap-[0]">
                        <svg className="mr-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M9.30135 13.2602L11.0795 18.8333H7.43328L5.67018 13.7965M9.30135 13.2602C8.08628 13.3982 6.87878 13.6124 5.67018 13.7965M9.30135 13.2602C11.5666 13.0028 13.8585 13.0856 16.101 13.5068M5.67018 13.7965L4.31011 14.0037C2.62495 12.8152 2.02939 10.5925 2.89453 8.72064L6.78599 7.20027C9.18256 6.26396 11.403 4.93005 13.3542 3.25553M16.101 13.5068C16.2314 13.582 16.3536 13.6078 16.4644 13.5781C17.1939 13.3826 17.1514 10.8589 16.3696 7.94116C15.5878 5.02343 14.3627 2.81659 13.6333 3.01204C13.5225 3.04173 13.4295 3.12516 13.3542 3.25553M16.101 13.5068C15.373 13.0866 14.3911 11.1234 13.7281 8.64895C13.0651 6.17447 12.9338 3.98336 13.3542 3.25553" stroke="#161A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dark:stroke-white"/>
                        </svg>
                        <h3 className="text-[#161A1D] text-base font-medium lg:leading-[24px] dark:text-white">
                            New   
                        </h3> 
                    </div>
                    <div className="w-[1px] h-[16px] bg-[#B4B9C9] leading-[24px] my-[2px] mx-[8px]"></div>
                    <div className="flex items-center gap-[0]">
                        <h3 className="text-[#161A1D] text-[12px] font-normal lg:text-base lg:leading-[24px] dark:text-white">We are introducing this new feature</h3> 
                        <svg className="ml-2" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                            <path d="M10.0428 13.8333C11.23 12.969 12.2961 11.9595 13.2151 10.8301C13.294 10.7332 13.3334 10.6166 13.3334 10.4999M10.0428 7.16659C11.23 8.03079 12.2961 9.04035 13.2151 10.1697C13.294 10.2666 13.3334 10.3833 13.3334 10.4999M13.3334 10.4999L6.6667 10.4999M17.625 10.5C17.625 14.7112 14.2112 18.125 10 18.125C5.78883 18.125 2.375 14.7112 2.375 10.5C2.375 6.28883 5.78883 2.875 10 2.875C14.2112 2.875 17.625 6.28883 17.625 10.5Z" stroke="#161A1D" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" className="dark:stroke-white"/>
                        </svg>                  
                    </div>
                </div>
            </ShineBorder>
        </Link>
    </div>
  )
}

export default NewSectionTitle
