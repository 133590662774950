
import AccordionFaqs from "../../components/accordionFaqs/AccordionFaqs"
import SectionTitle from "../../components/sectionTitle/SectionTitle"

const Faqs = () => {
    document.title = "Biorce - FAQs"


    return (
    <div className="flex flex-col justify-center items-start mx-10 lg:mx-20 mb-20 max-w-7xl xl:mx-auto">

        <SectionTitle 
            title= "Common Questions Answered" 
            icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                <path className='dark:stroke-[#99ECF3]' d="M13.3327 7.33301C13.3327 6.22844 12.4373 5.33301 11.3327 5.33301L10.9327 5.33301C10.6849 5.33301 10.5611 5.33301 10.4574 5.31659C9.88691 5.22623 9.43946 4.77878 9.3491 4.20825C9.33268 4.10461 9.33268 3.98074 9.33268 3.73301V3.33301C9.33268 2.22844 8.43725 1.33301 7.33268 1.33301M6.566 8.00117C6.68346 7.66725 6.91531 7.38568 7.22049 7.20633C7.52566 7.02697 7.88446 6.96141 8.23334 7.02125C8.58222 7.08109 8.89867 7.26248 9.12663 7.53328C9.35459 7.80408 9.47935 8.14682 9.47883 8.50079C9.47883 9.50005 7.97994 9.99967 7.97994 9.99967M7.99935 11.9997H8.00602M13.3327 6.66634V11.9997C13.3327 13.4724 12.1388 14.6663 10.666 14.6663H5.33268C3.85992 14.6663 2.66602 13.4724 2.66602 11.9997V3.99967C2.66602 2.52692 3.85992 1.33301 5.33268 1.33301H7.99935C10.9449 1.33301 13.3327 3.72082 13.3327 6.66634Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />

        <h1 className="text-[#001A4F] dark:text-[#99ECF3] text-[25px] md:text-[50px] lg:text-[59px] mt-[50px] md:mt-[50px] mb-[30px] font-TTBold">FAQs</h1>

        <div className="w-[100%] h-[2px] bg-[#001A4F] dark:bg-[#99ECF3]"></div>

        <AccordionFaqs
            number="01"
            question="What is Biorce?"
            answered="Biorce is a healthcare technology company dedicated to revolutionizing clinical trials by developing innovative AI technologies. Our innovative platform, Jarvis, is the first testament to our dedication to streamlining the research process, accelerating the development of life-saving treatments, and improving patient outcomes."
        />

        <AccordionFaqs
            number="02"
            question="What is Jarvis?"
            answered="Information and time is key. With this mindset, we have developed Jarvis, the worlds first AI clinical trials assistant and is our first flagship platform among a promising future of innovative technologies that aspire to streamline the healthcare process making it more efficient. Jarvis leverages advanced algorithms to accelerate data analysis, improve patient recruitment, and enhance overall trial efficiency - and it all starts with gathering the right information, on time, not only as a patient but also as a physician!"
        />

        <AccordionFaqs
            number="03"
            question="Is my data secure with Jarvis?"
            answered="Yes, your data is secure with Jarvis. We prioritize data privacy and employ robust security measures to protect your information. If you have any concerns please contact us at support@biorce.com"
        />

        <AccordionFaqs
            number="04"
            question="How does Jarvis work?"
            answered="Jarvis uses AI to analyze large datasets, identify patterns and trends, and provide valuable insights for patients and physicians. It can help streamline tasks, saving precious time and resources for matters that usually lack either time or coherent information sharing."
        />

        <AccordionFaqs
            number="05"
            question="How can I access Jarvis?"
            answered="The process is straightforward, to align with our value of efficiency. The only step you need to take is to sign up for the platform and shortly after, our team will review your application and allow you access. That’s it!"
        />

        <AccordionFaqs
            number="06"
            question="How long does it take to get started with Jarvis?"
            answered="The onboarding process for Jarvis can typically be completed within a few seconds! All you have to do is log-in or sign-up and you gain immediate access to the tool.
            Caution: since Jarvis is currently on a Beta Version, the onboarding process includes review of your application which is followed by allowing you access, which can take up to 48 hours."
        />

        <AccordionFaqs
            number="07"
            question="Is there a cost to use Jarvis?"
            answered="Nope! We promise to maintain accessible and inclusive, and we also believe that such a crucial tool for healthcare knowledge should remain FREE."
        />

        <AccordionFaqs
            number="08"
            question="What are the benefits of joining the beta program?"
            answered="By joining the beta program, you'll gain early access to Jarvis and have the opportunity to provide valuable feedback that will help shape the future of the platform. Additionally, you'll be among the first to experience the benefits of our AI-powered clinical trial optimization solution. Using Jarvis can lead to faster clinical trials, improved data analysis, optimized patient recruitment, reduced costs, and ultimately, better patient outcomes. We are committed to providing you with valuable insights that aspire to streamline the entire research process."
        />

        <AccordionFaqs
            number="09"
            question="Can Jarvis be used for all types of clinical trials, conditions and medical history?"
            answered="Yes, and all you have to do is access Jarvis, and provide the information you need answers to and Jarvis will provide you answers in the most optimal way possible. Currently, Jarvis is in a Beta Version, and using it means you are participating in our Beta program, therefore, we are doing our best to fulfill our commitment to developing Jarvis in a way that provides you with the most coherent answers possible in a holistic manner. Nevertheless, the future is really promising!"
        />

        <AccordionFaqs
            number="10"
            question="How does Jarvis compare to other AI solutions for clinical trials?"
            answered="Jarvis offers a unique combination of advanced features and capabilities, designed specifically to address the challenges of clinical research. Remember, this is not another GPT and never will - we employ the best of all words to provide you with the most innovative tool to meet your healthcare needs."
        />
    </div>
    )
}

export default Faqs