import { useState } from 'react';
import "./Subscribe.css";

const Subscribe = () => {
  const [message, setMessage] = useState("Subcribe Now");
  const [email, setEmail] = useState("");


  const handleSubmit = async (event:any) => {
    event.preventDefault();

    const requestBody = {
      fields: [
        {
          objectTypeId: "0-1",
          name: "email",
          value: email,
        },
      ],
    }

    try {
      const response = await fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${import.meta.env.VITE_PORTAL_ID}/${import.meta.env.VITE_FORM_GUID}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestBody),
        }
      )
      if (response.ok) {
        const result = await response.json()
        setMessage(result.inlineMessage)
        setEmail("")
      }
    } catch (error) {  
      setMessage("Something went wrong, please try again.")
    }
  }

  return (
    <div className="mt-10 flex mx-10 lg:mx-20 mb-[50px] lg:mb-[150px] max-w-7xl 2xl:mx-auto">
      <div className="flex p-2 lg:py-[48px] lg:px-[40px] flex-col justify-center items-start gap-[24px] self-stretch rounded-[8px] w-full border-[0.66px] border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0)_100%)] backdrop-blur-[12px] dark:bg-[#161A1D] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgb(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)] dark:backdrop-blur-[10px]">
        <form className="block mx-auto md:flex lg:flex p-2 lg:p-0 justify-center items-center gap-[48px] self-stretch" onSubmit={handleSubmit}>
          <div className="mb-[20px] lg:mb-0">
            <input
              className="w-[280px] md:w-[350px] lg:w-[685px] h-[44px] md:mt-[20px] lg:mt-0 p-[10px_30px] flex items-center rounded-[10px] border border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0)_100%)] backdrop-blur-[17.5px] placeholder:text-[#001a4f72] dark:text-[#99ECF3]
              dark:bg-[#161A1D] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgb(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)] dark:backdrop-blur-[10px] dark:placeholder-[#99ecf360]"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button
            type="submit"
            className="suscribeButton mx-auto font-TTBold dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0)_98.58%),_linear-gradient(114deg,_rgb(22,_26,_29,_0.50)_0%,_rgba(22,_26,_29,_0)_100%)]"
          >
            {message}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Subscribe;
