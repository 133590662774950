import { useEffect, useRef } from 'react'
import "./CasesStudiesCards.css"

const CasesStudiesCards = (prop:any) => {

    const icon1 = prop.icon1
    const title1 = prop.title1
    const text1 = prop.text1

    const icon2 = prop.icon2
    const title2 = prop.title2
    const text2 = prop.text2

    const icon3 = prop.icon3
    const title3 = prop.title3
    const text3 = prop.text3


    const applyCardAnimationObserver = (element:any, delay:any) => {
        let timeoutId:any = null
      
        const observer = new IntersectionObserver(
          ([entry]) => {
            if (entry.isIntersecting) {
              if (timeoutId) {
                clearTimeout(timeoutId)
                timeoutId = null
              }
              timeoutId = setTimeout(() => {
                element.classList.add('show')
              }, delay * 200)
            } else {
              if (timeoutId) {
                clearTimeout(timeoutId);
                timeoutId = null
              }
              element.classList.remove('show')
            }
          },
          {
            threshold: 0.1,
          }
        )
      
        observer.observe(element)
      
        return () => {
          observer.unobserve(element)
          if (timeoutId) {
            clearTimeout(timeoutId)
            timeoutId = null
          }
        }
      }
      
      
          const delay1 = 1000
          const delay2 = 1000
          const delay3 = 1000
        
          const ref1 = useRef(null)
          const ref2 = useRef(null)
          const ref3 = useRef(null)
        
          useEffect(() => {
            const cleanup1 = ref1.current ? applyCardAnimationObserver(ref1.current, delay1 / 1000) : undefined
            const cleanup2 = ref2.current ? applyCardAnimationObserver(ref2.current, delay2 / 1000) : undefined
            const cleanup3 = ref3.current ? applyCardAnimationObserver(ref3.current, delay3 / 1000) : undefined
        
            return () => {
              if (cleanup1) cleanup1()
              if (cleanup2) cleanup2()
              if (cleanup3) cleanup3()
            };
          }, [delay1, delay2, delay3])

  return (
    <div className="block md:flex lg:flex items-center justify-center mb-[100px] md:mb-[200px] pg:mb-[300px]">

        <div ref={ref1} className="casesStudiesCardsAnimation px-0 md:px-2 lg:px-4 py-0 md:py-5 lg:py-10 flex flex-col justify-center items-center self-stretch">
            <div className="card rounded-[18px] border border-custom bg-custom-gradient backdrop-filter backdrop-blur-17.5 p-0 md:p-5 lg:p-10 w-[274px] md:w-[250px] lg:w-[380px] h-[179px] md:h-[250px] lg:h-[380px]">
                <div className="flex items-center justify-center gap-[10px] iconContainer"> {icon1} </div>
                <div className="flex p-2  justify-center items-center gap-2.5">
                    <h3 className="text-[#001A4F] text-[18px] md:text-[16px] lg:text-[25px] font-semibold leading-[56px] my-0 lg:my-5 mx-auto dark:text-[#99ECF3]"> {title1} </h3>
                </div>
                <div className="flex p-2 justify-center items-center gap-2.5 self-stretch w-[300px]">
                    <p className="text-[#22272B] font-inter text-base font-normal leading-6"> {text1} </p>
                </div>
            </div>
        </div>

        <div ref={ref2} className="casesStudiesCardsAnimation2 px-0 md:px-2 lg:px-4 py-0 md:py-5 lg:py-10 flex flex-col justify-center items-center self-stretch">
            <div className="card rounded-[18px] border border-custom bg-custom-gradient backdrop-filter backdrop-blur-17.5 p-0 md:p-5 lg:p-10 w-[274px] md:w-[250px] lg:w-[380px] h-[179px] md:h-[250px] lg:h-[380px]">
                <div className="flex items-center justify-center gap-[10px] iconContainer"> {icon2} </div>
                <div className="flex p-2 justify-center items-center gap-2.5">
                    <h3 className="text-[#001A4F] text-[18px] md:text-[16px] lg:text-[25px] font-semibold leading-[56px] my-0 lg:my-5 mx-auto dark:text-[#99ECF3]"> {title2} </h3>
                </div>
                <div className="flex p-2 justify-center items-center gap-2.5 self-stretch w-[300px]">
                    <p className="text-[#22272B] font-inter text-base font-normal leading-6"> {text2} </p>
                </div>
            </div>
        </div>

        <div ref={ref3} className="casesStudiesCardsAnimation3 px-0 md:px-2 lg:px-4 py-0 md:py-5 lg:py-10 flex flex-col justify-center items-center self-stretch">
            <div className="card rounded-[18px] border border-custom bg-custom-gradient backdrop-filter backdrop-blur-17.5 p-0 md:p-5 lg:p-10 w-[274px] md:w-[250px] lg:w-[380px] h-[179px] md:h-[250px] lg:h-[380px]">
                <div className="flex items-center justify-center gap-[10px] iconContainer"> {icon3} </div>
                <div className="flex p-2 justify-center items-center gap-2.5">
                    <h3 className="text-[#001A4F] text-[18px] md:text-[16px] lg:text-[25px] font-semibold leading-[56px] my-0 lg:my-5 mx-auto dark:text-[#99ECF3]"> {title3} </h3>
                </div>
                <div className="flex p-2 justify-center items-center gap-2.5 self-stretch w-[300px]">
                    <p className="text-[#22272B] font-inter text-base font-normal leading-6"> {text3} </p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default CasesStudiesCards
