
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
  useNavigate
} from "react-router-dom"
import { useEffect, useRef, useState } from "react"
import './App.css'
import Home from './pages/home/Home'
import About from "./pages/about/About"
import Jarvis from "./pages/jarvis/Jarvis"
//import Blog from "./pages/blog/Blog"
import Contact from "./pages/contact/Contact"
//import AiEthicsAndSafety from "./pages/ai-ethics-and-safety/AiEthicsAndSafety"
import Careers from "./pages/careers/Careers"
import Navbar from "./components/navbar/Navbar"
import Footer from "./components/footer/Footer"
import ButtonUpPage from "./components/buttonUpPage/ButtonUpPage"
import TransitionButtons from "./components/transitionButtons/TransitionButtons"
import TransitionComponent from './components/transition/Transition'
import { TransitionProvider } from "./context/TransitionContext"
import { ThemeProvider } from "./context/Theme-provider"
import ButtonMouseScroll from "./components/buttonMouseScroll/ButtonMouseScroll"
import TermsOfUse from "./pages/termsOfUse/TermsOfUse"
import PrivacyPolicy from "./pages/privacy-policy/PrivacyPolicy"
import Faqs from "./pages/faqs/Faqs"

const routesDirection = [
  "/home", "/about", "/jarvis", "/careers", "/contact", "/terms-of-use", "/privacy-Policy", "/faqs"
]

function App() {

  return (
    <BrowserRouter>
      <TransitionProvider>
        <div className="gradient-background dark:bg-[#161A1D]">
          <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <Navbar />
            <ButtonMouseScroll/>
            <TransitionButtons/>
            <ButtonUpPage/>
            <Routes>
              <Route path="/" element={<Navigate to="/home" replace />} />
              {routesDirection.map((path) => (
                <Route
                  key={path}
                  path={path}
                  element={<TransitionedComponent  />}
                />
              ))}
            </Routes>              
            <Footer />
          </ThemeProvider>
        </div>
      </TransitionProvider>
    </BrowserRouter>
  );
}


function TransitionedComponent() {
  const location = useLocation()
  const navigate = useNavigate()

  const currentPath = location.pathname
  const [enterDirection, setEnterDirection] = useState(currentPath)
  const previousDirectionRef = useRef(currentPath)
  const [isNavigating, setIsNavigating] = useState(false)
  const [isIntermediate, setIsIntermediate] = useState(false)
  

  useEffect(() => {
    if (isNavigating) return
    setEnterDirection(currentPath)
    previousDirectionRef.current = enterDirection
    handleIntermediate2()
  }, [currentPath])

  const handleIntermediate2 = async () => {
    const previousPage = previousDirectionRef.current
    let index = routesDirection.indexOf(previousPage)
    const interPages = []
    const actualIndex = routesDirection.indexOf(currentPath)
    const start = Math.min(index, actualIndex) + 1
    const end = Math.max(index, actualIndex)

    if (start < end) {
      for (let i = start; i < end; i++) {
        interPages.push(routesDirection[i])
      }
      setIsIntermediate(true)
    } else {
      setIsIntermediate(false)
    }
  
     if(index > actualIndex){
      const interPagesReverse = interPages.reverse()
      setIsNavigating(true)
      if(interPagesReverse.length === 1){    
          navigate(interPagesReverse[0])
          await new Promise(resolve => setTimeout(resolve, 200))      
      }else{
        for (let i = 0; i < interPages.length; i++) {
           const element = interPagesReverse[i]      
          navigate(element)
          if(interPages.length === 3){
            await new Promise(resolve => setTimeout(resolve, 100))
          }else{
            await new Promise(resolve => setTimeout(resolve, 200))
          }
        }
      }
     setIsNavigating(false)
    }else if (index < actualIndex) {
        setIsNavigating(true)
        if(interPages.length === 1){
          navigate(interPages[0])
          await new Promise(resolve => setTimeout(resolve, 200))  
        }else{
         for (let i = 0; i < interPages.length; i++) {
           const element = interPages[i]       
           navigate(element)
           if(interPages.length === 3){
            await new Promise(resolve => setTimeout(resolve, 100))
          }else{
            await new Promise(resolve => setTimeout(resolve, 200))
          }
      }
    }
      setIsNavigating(false)
      }
    setIsIntermediate(false)
    navigate(currentPath)
  }

  return (
    <TransitionComponent direction={currentPath} isNavigating={isNavigating} isIntermediate={isIntermediate}>
      { getComponentForPath(currentPath) }
    </TransitionComponent>
  );
}

function getComponentForPath(path:string) {
  window.scrollTo(0, 0)
    
  switch (path) {
    case "/home":
      return<Home />  
    case "/about":
      return <About />
    case "/jarvis":
      return <Jarvis />
    // case "/blog":
    //   return <Blog />
    case "/contact":
      return <Contact />
    // case "/AI-ethics-and-safety":
    //   return <AiEthicsAndSafety />
    case "/careers":
      return <Careers />
    case "/terms-of-use":
      return <TermsOfUse />
    case "/privacy-Policy":
      return <PrivacyPolicy/>
      case "/faqs":
        return <Faqs/>
    default:
      return null
  }
}

export default App