import { useState, useEffect } from "react";

const ButtonUpPage = () => {
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const [navbarVisible, setNavbarVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarVisible(true);
      } else {
        setNavbarVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <button
      onClick={scrollToTop}
      className={`hidden md:flex group fixed bottom-[20px] right-[20px] z-50 w-[32px] h-[78px] p-[27px_4px] md:flex-col content-center items-center
        overflow-hidden rounded-[1000px] border border-[#5eaeae80] bg-[linear-gradient(0deg,rgba(165,246,255,0.08)0%,rgba(165,246,255,0.08)100%),rgba(254,254,254,0.80)] backdrop-blur-[5px] ${navbarVisible ? 'block opacity-100' : 'opacity-0'} duration-1000`}
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
        <path className="dark:stroke-white/50 dark:group-hover:stroke-white group-hover:stroke-[#001A4F] stroke-[#001a4f80]" d="M18 9.8304C16.4444 7.727 14.6272 5.83783 12.5943 4.20952C12.4199 4.06984 12.21 4 12 4M6 9.8304C7.55556 7.727 9.37278 5.83783 11.4057 4.20952C11.5801 4.06984 11.79 4 12 4M12 4L12 20" stroke="#001A4F" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      </svg>
      <div className="h-[2px] w-[14px] rounded-[3px] group-hover:bg-[#001A4F] bg-[#001A4F80] dark:bg-white/50 dark:group-hover:bg-white"/>
    </button>
  )
}

export default ButtonUpPage

