
import { useState, useEffect, useRef } from 'react'
import { Dialog, DialogPanel, PopoverGroup } from '@headlessui/react'
import { XMarkIcon, Bars2Icon } from '@heroicons/react/24/outline'
import { Link, useLocation } from "react-router-dom"
import Switcher from '../switch/Switch'

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)
  const [navbarVisible, setNavbarVisible] = useState(false)
  const menuRef = useRef<HTMLDivElement | null>(null)
  const location = useLocation()

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarVisible(true)
      } else {
        setNavbarVisible(false)
      }
    }

    const handleClickOutside = (event: MouseEvent) => {
      if (menuRef.current && event.target instanceof Node && !menuRef.current.contains(event.target)) {
        setMobileMenuOpen(false)
      }
    }

    window.addEventListener('scroll', handleScroll)
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [menuRef])

  return (

      <header className='bg-transparent'>
        <nav className="mx-10 lg:mx-20 flex w-7xl items-center justify-between py-8 bg-transparent" aria-label="Global">

          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700 mr-1"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>

          <div className="flex">
              <Link to="/home" className="flex items-center -m-1.5 p-1.5">
                <span className="sr-only">BIORCE</span>
                <svg className={` ${mobileMenuOpen ? 'opacity-0' : ''}`} xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21.2297 17.6887C21.205 17.4176 21.2112 17.1373 21.1465 16.8754C20.823 15.5385 19.4368 14.4973 18.0752 14.5435C16.6027 14.5958 15.3581 15.6617 15.1148 17.0942C15.0162 17.6887 15.0162 18.3079 14.8468 18.8809C14.3169 20.6553 12.8875 21.678 10.9067 21.8228C10.4847 21.8536 10.0473 21.9399 9.65605 22.097C8.0326 22.7439 7.33023 24.7463 8.1435 26.3204C8.966 27.9131 10.9807 28.483 12.4717 27.5496C13.4728 26.9242 13.9442 25.9908 13.9842 24.8479C14.0089 24.1086 14.166 23.4247 14.5449 22.7963C15.4074 21.3761 16.7136 20.7662 18.3401 20.7323C19.8927 20.6984 21.3128 19.1766 21.2297 17.6918V17.6887Z" fill="url(#paint0_linear_1588_1641)"/>
                    <path d="M10.8111 20.7053C11.0822 20.6806 11.3625 20.6868 11.6243 20.6221C12.9613 20.2986 14.0025 18.9124 13.9563 17.5508C13.9039 16.0783 12.8381 14.8337 11.4056 14.5904C10.8111 14.4918 10.1919 14.4918 9.6189 14.3224C7.8445 13.7925 6.82176 12.3631 6.67698 10.3823C6.64617 9.9603 6.55991 9.52286 6.40281 9.13163C5.75589 7.50818 3.75353 6.80582 2.17937 7.61908C0.58673 8.44159 0.0168285 10.4563 0.950235 11.9473C1.57559 12.9484 2.50899 13.4198 3.65188 13.4598C4.39121 13.4844 5.07509 13.6416 5.70352 14.0205C7.12365 14.883 7.7336 16.1892 7.76749 17.8157C7.80138 19.3683 9.32317 20.7884 10.808 20.7053H10.8111Z" fill="url(#paint1_linear_1588_1641)"/>
                    <path d="M7.8443 10.3111C7.86895 10.5822 7.86278 10.8625 7.92748 11.1243C8.25093 12.4613 9.63718 13.5025 10.9988 13.4563C12.4713 13.4039 13.7158 12.3381 13.9592 10.9056C14.0578 10.3111 14.0578 9.69188 14.2272 9.1189C14.7571 7.3445 16.1864 6.32176 18.1672 6.17698C18.5893 6.14617 19.0267 6.05991 19.4179 5.90281C21.0414 5.25589 21.7437 3.25353 20.9305 1.67937C20.108 0.0867299 18.0933 -0.483171 16.6023 0.450235C15.6011 1.07559 15.1298 2.00899 15.0898 3.15188C15.0651 3.89121 14.908 4.57509 14.5291 5.20352C13.6665 6.62365 12.3604 7.2336 10.7339 7.26749C9.18126 7.30138 7.76113 8.82317 7.8443 10.308V10.3111Z" fill="url(#paint2_linear_1588_1641)"/>
                    <path d="M18.2629 7.29547C17.9918 7.32012 17.7115 7.31396 17.4497 7.37865C16.1127 7.70211 15.0715 9.08835 15.1177 10.45C15.1701 11.9225 16.2359 13.167 17.6684 13.4104C18.2629 13.5089 18.8821 13.5089 19.4551 13.6784C21.2295 14.2082 22.2522 15.6376 22.397 17.6184C22.4278 18.0404 22.5141 18.4779 22.6712 18.8691C23.3181 20.4925 25.3205 21.1949 26.8946 20.3816C28.4873 19.5591 29.0572 17.5445 28.1238 16.0535C27.4984 15.0523 26.565 14.581 25.4221 14.5409C24.6828 14.5163 23.9989 14.3592 23.3705 13.9803C21.9503 13.1177 21.3404 11.8116 21.3065 10.185C21.2726 8.63243 19.7508 7.2123 18.266 7.29547H18.2629Z" fill="url(#paint3_linear_1588_1641)"/>
                    <path d="M11.2916 6.34654C13.1183 6.32805 14.3228 4.5629 14.2335 3.09656C14.1318 1.43922 12.5823 0.0652984 10.9743 0.176198C9.19063 0.29942 7.89063 1.81505 8.03542 3.60793C8.1648 5.19441 9.68043 6.46976 11.2916 6.34654Z" fill="url(#paint4_linear_1588_1641)"/>
                    <path d="M25.5147 13.5858C27.3415 13.5673 28.546 11.8022 28.4566 10.3358C28.355 8.67848 26.8055 7.30456 25.1974 7.41546C23.4138 7.53868 22.1138 9.05431 22.2586 10.8472C22.3879 12.4337 23.9036 13.709 25.5147 13.5858Z" fill="url(#paint5_linear_1588_1641)"/>
                    <path d="M3.91363 20.5389C5.7404 20.5204 6.94489 18.7553 6.85555 17.2889C6.7539 15.6316 5.20438 14.2577 3.59633 14.3686C1.8127 14.4918 0.512705 16.0074 0.657491 17.8003C0.786874 19.3868 2.3025 20.6621 3.91363 20.5389Z" fill="url(#paint6_linear_1588_1641)"/>
                    <path d="M18.192 27.8514C20.0187 27.8329 21.2232 26.0678 21.1339 24.6014C21.0322 22.9441 19.4827 21.5702 17.8747 21.6811C16.091 21.8043 14.791 23.3199 14.9358 25.1128C15.0652 26.6993 16.5808 27.9746 18.192 27.8514Z" fill="url(#paint7_linear_1588_1641)"/>
                    <defs>
                        <linearGradient id="paint0_linear_1588_1641" x1="11.6615" y1="37.5305" x2="17.0802" y2="6.80513" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint1_linear_1588_1641" x1="5.99925" y1="20.9763" x2="10.4691" y2="-4.37349" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint2_linear_1588_1641" x1="11.6703" y1="23.0245" x2="17.089" y2="-7.70398" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint3_linear_1588_1641" x1="18.3399" y1="33.8837" x2="22.8098" y2="8.53385" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint4_linear_1588_1641" x1="9.09205" y1="14.8365" x2="14.0548" y2="-13.3135" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint5_linear_1588_1641" x1="20.8569" y1="36.0307" x2="25.8197" y2="7.88062" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint6_linear_1588_1641" x1="2.98947" y1="21.7865" x2="7.95223" y2="-6.36043" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                        <linearGradient id="paint7_linear_1588_1641" x1="14.7972" y1="43.1094" x2="19.763" y2="14.9624" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#A5F6FF"/>
                        <stop offset="1" stop-color="#5EBABA"/>
                        </linearGradient>
                    </defs>
                </svg>
                <h1 className="text-[24px] text-[#001A4F] font-semibold leading-[32px] tracking-[6px] mr-10 ml-[5px] pb-[5px] dark:text-white">BIORCE</h1>
              </Link>
            </div>

          <PopoverGroup className="hidden lg:flex lg:gap-x-12">
              <Link to="/about" className={`flex p-0 justify-center content-center gap-[16px] text-[#454f5991] hover:text-[#454F59] ${location.pathname === "/about" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                  ABOUT
              </Link>

              <Link to="/jarvis" className={`flex p-0 justify-center content-center gap-[16px] text-[#454f5991] hover:text-[#454F59] ${location.pathname === "/jarvis" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                  JARVIS
              </Link>

              <Link to="/careers" className={`flex p-0 justify-center content-center gap-[16px] text-[#454f5991] hover:text-[#454F59] ${location.pathname === "/careers" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                  CAREERS
              </Link> 

              <Link to="/contact" className={`flex p-0 justify-center content-center gap-[16px] text-[#454f5991] hover:text-[#454F59] ${location.pathname === "/contact" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                  CONTACT
              </Link>
          </PopoverGroup>
          
          <div className="hidden lg:flex lg:flex-1 lg:justify-end">
              <Link to="https://auth-app.biorce.com/login" target='_blank' className="flex py-[5px] justify-center content-center gap-[16px] text-[#454f5991] hover:text-[#454F59]">
              Log in 
              </Link>
              <div className="flex p-0 justify-center content-center gap-[16px] ">
                <Switcher/>
              </div>
          </div>

          <div className={`flex lg:hidden ${mobileMenuOpen ? 'opacity-0' : ''}`}>
            <Switcher/>
          </div>
        </nav>

        <Dialog className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
          <div className="fixed inset-0 z-10" />
          <DialogPanel className="mx-10 fixed inset-y-0 z-10 overflow-y-auto bg-transparent py-[27px] sm:w-[24rem] md:w-[45.5rem]  sm:ring-gray-900/10">
            <div className="flex items-center justify-between bg-white dark:bg-[#161A1D]">

              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-700"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>

              <Link to="/home"  onClick={() => setMobileMenuOpen(false)} className="-m-1.5 p-1.5 flex ml-2">
                <span className="sr-only">BIORCE</span>
                <svg className='mt-[5px] ml-[3px]' xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M21.2297 17.6887C21.205 17.4176 21.2112 17.1373 21.1465 16.8754C20.823 15.5385 19.4368 14.4973 18.0752 14.5435C16.6027 14.5958 15.3581 15.6617 15.1148 17.0942C15.0162 17.6887 15.0162 18.3079 14.8468 18.8809C14.3169 20.6553 12.8875 21.678 10.9067 21.8228C10.4847 21.8536 10.0473 21.9399 9.65605 22.097C8.0326 22.7439 7.33023 24.7463 8.1435 26.3204C8.966 27.9131 10.9807 28.483 12.4717 27.5496C13.4728 26.9242 13.9442 25.9908 13.9842 24.8479C14.0089 24.1086 14.166 23.4247 14.5449 22.7963C15.4074 21.3761 16.7136 20.7662 18.3401 20.7323C19.8927 20.6984 21.3128 19.1766 21.2297 17.6918V17.6887Z" fill="url(#paint0_linear_1588_1641)"/>
                  <path d="M10.8111 20.7053C11.0822 20.6806 11.3625 20.6868 11.6243 20.6221C12.9613 20.2986 14.0025 18.9124 13.9563 17.5508C13.9039 16.0783 12.8381 14.8337 11.4056 14.5904C10.8111 14.4918 10.1919 14.4918 9.6189 14.3224C7.8445 13.7925 6.82176 12.3631 6.67698 10.3823C6.64617 9.9603 6.55991 9.52286 6.40281 9.13163C5.75589 7.50818 3.75353 6.80582 2.17937 7.61908C0.58673 8.44159 0.0168285 10.4563 0.950235 11.9473C1.57559 12.9484 2.50899 13.4198 3.65188 13.4598C4.39121 13.4844 5.07509 13.6416 5.70352 14.0205C7.12365 14.883 7.7336 16.1892 7.76749 17.8157C7.80138 19.3683 9.32317 20.7884 10.808 20.7053H10.8111Z" fill="url(#paint1_linear_1588_1641)"/>
                  <path d="M7.8443 10.3111C7.86895 10.5822 7.86278 10.8625 7.92748 11.1243C8.25093 12.4613 9.63718 13.5025 10.9988 13.4563C12.4713 13.4039 13.7158 12.3381 13.9592 10.9056C14.0578 10.3111 14.0578 9.69188 14.2272 9.1189C14.7571 7.3445 16.1864 6.32176 18.1672 6.17698C18.5893 6.14617 19.0267 6.05991 19.4179 5.90281C21.0414 5.25589 21.7437 3.25353 20.9305 1.67937C20.108 0.0867299 18.0933 -0.483171 16.6023 0.450235C15.6011 1.07559 15.1298 2.00899 15.0898 3.15188C15.0651 3.89121 14.908 4.57509 14.5291 5.20352C13.6665 6.62365 12.3604 7.2336 10.7339 7.26749C9.18126 7.30138 7.76113 8.82317 7.8443 10.308V10.3111Z" fill="url(#paint2_linear_1588_1641)"/>
                  <path d="M18.2629 7.29547C17.9918 7.32012 17.7115 7.31396 17.4497 7.37865C16.1127 7.70211 15.0715 9.08835 15.1177 10.45C15.1701 11.9225 16.2359 13.167 17.6684 13.4104C18.2629 13.5089 18.8821 13.5089 19.4551 13.6784C21.2295 14.2082 22.2522 15.6376 22.397 17.6184C22.4278 18.0404 22.5141 18.4779 22.6712 18.8691C23.3181 20.4925 25.3205 21.1949 26.8946 20.3816C28.4873 19.5591 29.0572 17.5445 28.1238 16.0535C27.4984 15.0523 26.565 14.581 25.4221 14.5409C24.6828 14.5163 23.9989 14.3592 23.3705 13.9803C21.9503 13.1177 21.3404 11.8116 21.3065 10.185C21.2726 8.63243 19.7508 7.2123 18.266 7.29547H18.2629Z" fill="url(#paint3_linear_1588_1641)"/>
                  <path d="M11.2916 6.34654C13.1183 6.32805 14.3228 4.5629 14.2335 3.09656C14.1318 1.43922 12.5823 0.0652984 10.9743 0.176198C9.19063 0.29942 7.89063 1.81505 8.03542 3.60793C8.1648 5.19441 9.68043 6.46976 11.2916 6.34654Z" fill="url(#paint4_linear_1588_1641)"/>
                  <path d="M25.5147 13.5858C27.3415 13.5673 28.546 11.8022 28.4566 10.3358C28.355 8.67848 26.8055 7.30456 25.1974 7.41546C23.4138 7.53868 22.1138 9.05431 22.2586 10.8472C22.3879 12.4337 23.9036 13.709 25.5147 13.5858Z" fill="url(#paint5_linear_1588_1641)"/>
                  <path d="M3.91363 20.5389C5.7404 20.5204 6.94489 18.7553 6.85555 17.2889C6.7539 15.6316 5.20438 14.2577 3.59633 14.3686C1.8127 14.4918 0.512705 16.0074 0.657491 17.8003C0.786874 19.3868 2.3025 20.6621 3.91363 20.5389Z" fill="url(#paint6_linear_1588_1641)"/>
                  <path d="M18.192 27.8514C20.0187 27.8329 21.2232 26.0678 21.1339 24.6014C21.0322 22.9441 19.4827 21.5702 17.8747 21.6811C16.091 21.8043 14.791 23.3199 14.9358 25.1128C15.0652 26.6993 16.5808 27.9746 18.192 27.8514Z" fill="url(#paint7_linear_1588_1641)"/>
                  <defs>
                      <linearGradient id="paint0_linear_1588_1641" x1="11.6615" y1="37.5305" x2="17.0802" y2="6.80513" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint1_linear_1588_1641" x1="5.99925" y1="20.9763" x2="10.4691" y2="-4.37349" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint2_linear_1588_1641" x1="11.6703" y1="23.0245" x2="17.089" y2="-7.70398" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint3_linear_1588_1641" x1="18.3399" y1="33.8837" x2="22.8098" y2="8.53385" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint4_linear_1588_1641" x1="9.09205" y1="14.8365" x2="14.0548" y2="-13.3135" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint5_linear_1588_1641" x1="20.8569" y1="36.0307" x2="25.8197" y2="7.88062" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint6_linear_1588_1641" x1="2.98947" y1="21.7865" x2="7.95223" y2="-6.36043" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint7_linear_1588_1641" x1="14.7972" y1="43.1094" x2="19.763" y2="14.9624" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                  </defs>
                </svg>
                <h1 className="text-[24px] text-[#001A4F] font-semibold leading-[32px] tracking-[6px] mr-[40px] md:mr-[35px] ml-[5px] pb-[5px] dark:text-white">BIORCE</h1>
              </Link>

              <Switcher/>

            </div>
            <div ref={menuRef} className="mt-7 flow-root">
              <div className="-my-6 divide-y divide-gray-500/10">
                <div className="space-y-1 py-6 text-center items-center rounded-[15px] border-[0.5px] border-white 
                
                bg-[linear-gradient(114deg,rgba(94,186,186,0.08)_1.41%,rgba(94,186,186,0.00)_98.58%),linear-gradient(114deg,rgba(254,254,254,0.50)_0%,rgba(254,254,254,0.00)_100%)] shadow-[0px_1px_4px_0px_rgba(42,59,81,0.12)] backdrop-blur-[17.5px]
                
                dark:bg-[linear-gradient(114deg,rgba(94,186,186,0.08)_1.41%,rgba(94,186,186,0.00)_98.58%),linear-gradient(114deg,rgba(22,26,29,0.50)_0%,rgba(22,26,29,0.00)_100%)] dark:border-[#454F59] dark:backdrop-blur-[7.5px]">

                  <Link to="/about" onClick={() => setMobileMenuOpen(false)}  className={`-mx-3 block rounded-lg px-3 py-0 text-base font-semibold leading-7 text-[#001A4F] dark:text-[#62BDBE] ${location.pathname === "/about" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                      ABOUT
                  </Link>

                  <Link to="/jarvis" onClick={() => setMobileMenuOpen(false)} className={`-mx-3 block rounded-lg px-3 py-0 text-base font-semibold leading-7 text-[#001A4F] dark:text-[#62BDBE] ${location.pathname === "/jarvis" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                      JARVIS
                  </Link>

                  <Link to="/careers" onClick={() => setMobileMenuOpen(false)} className={`-mx-3 block rounded-lg px-3 py-0 text-base font-semibold leading-7 text-[#001A4F] dark:text-[#62BDBE] ${location.pathname === "/careers" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                    CAREERS
                  </Link>

                  <Link to="/contact" onClick={() => setMobileMenuOpen(false)} className={`-mx-3 block rounded-lg px-3 py-0 text-base font-semibold leading-7 text-[#001A4F] dark:text-[#62BDBE] ${location.pathname === "/contact" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : ""}`}>
                      CONTACT
                  </Link>

                  <Link to="https://auth-app.biorce.com/login" onClick={() => setMobileMenuOpen(false)} target='_blank' className="-mx-3 block rounded-lg px-3 pt-10 text-base font-semibold leading-7 text-[#001A4F] hover:bg-gray-200 dark:text-[#62BDBE]">
                      Log in
                  </Link>

                </div>
              </div>
            </div>
          </DialogPanel>
        </Dialog>

        <nav className={`fixed top-4 z-50 w-full flex justify-center items-center ${navbarVisible ? 'block opacity-100' : 'opacity-0 mt-[-100px]'} duration-1000`}>
          <div className='hidden lg:flex p-4 w-[763px] justify-between items-center rounded-[15px] border-[0.5px] border-white 
          bg-gradient-to-t from-[rgba(254,254,254,0.20)] to-[rgba(254,254,254,0.20)] bg-[rgba(165,246,255,0.15)] shadow-[0px_1px_4px_0px_rgba(42,59,81,0.12)] backdrop-blur-[17.5px] dark:border-[#454F59] dark:backdrop-blur-[7.5px] dark:from-[rgba(22,26,29,0.70)] dark:to-[rgba(22,26,29,0.70)] dark:bg-[rgba(165,246,255,0.16)]'>
            <div className='flex w-[108px] items-center gap-0 flex-shrink-0 pl-[5px]'>
              <Link to="/home" className='flex items-center mr-[25px]'>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                  <path d="M21.2297 17.6887C21.205 17.4176 21.2112 17.1373 21.1465 16.8754C20.823 15.5385 19.4368 14.4973 18.0752 14.5435C16.6027 14.5958 15.3581 15.6617 15.1148 17.0942C15.0162 17.6887 15.0162 18.3079 14.8468 18.8809C14.3169 20.6553 12.8875 21.678 10.9067 21.8228C10.4847 21.8536 10.0473 21.9399 9.65605 22.097C8.0326 22.7439 7.33023 24.7463 8.1435 26.3204C8.966 27.9131 10.9807 28.483 12.4717 27.5496C13.4728 26.9242 13.9442 25.9908 13.9842 24.8479C14.0089 24.1086 14.166 23.4247 14.5449 22.7963C15.4074 21.3761 16.7136 20.7662 18.3401 20.7323C19.8927 20.6984 21.3128 19.1766 21.2297 17.6918V17.6887Z" fill="url(#paint0_linear_1588_1641)"/>
                  <path d="M10.8111 20.7053C11.0822 20.6806 11.3625 20.6868 11.6243 20.6221C12.9613 20.2986 14.0025 18.9124 13.9563 17.5508C13.9039 16.0783 12.8381 14.8337 11.4056 14.5904C10.8111 14.4918 10.1919 14.4918 9.6189 14.3224C7.8445 13.7925 6.82176 12.3631 6.67698 10.3823C6.64617 9.9603 6.55991 9.52286 6.40281 9.13163C5.75589 7.50818 3.75353 6.80582 2.17937 7.61908C0.58673 8.44159 0.0168285 10.4563 0.950235 11.9473C1.57559 12.9484 2.50899 13.4198 3.65188 13.4598C4.39121 13.4844 5.07509 13.6416 5.70352 14.0205C7.12365 14.883 7.7336 16.1892 7.76749 17.8157C7.80138 19.3683 9.32317 20.7884 10.808 20.7053H10.8111Z" fill="url(#paint1_linear_1588_1641)"/>
                  <path d="M7.8443 10.3111C7.86895 10.5822 7.86278 10.8625 7.92748 11.1243C8.25093 12.4613 9.63718 13.5025 10.9988 13.4563C12.4713 13.4039 13.7158 12.3381 13.9592 10.9056C14.0578 10.3111 14.0578 9.69188 14.2272 9.1189C14.7571 7.3445 16.1864 6.32176 18.1672 6.17698C18.5893 6.14617 19.0267 6.05991 19.4179 5.90281C21.0414 5.25589 21.7437 3.25353 20.9305 1.67937C20.108 0.0867299 18.0933 -0.483171 16.6023 0.450235C15.6011 1.07559 15.1298 2.00899 15.0898 3.15188C15.0651 3.89121 14.908 4.57509 14.5291 5.20352C13.6665 6.62365 12.3604 7.2336 10.7339 7.26749C9.18126 7.30138 7.76113 8.82317 7.8443 10.308V10.3111Z" fill="url(#paint2_linear_1588_1641)"/>
                  <path d="M18.2629 7.29547C17.9918 7.32012 17.7115 7.31396 17.4497 7.37865C16.1127 7.70211 15.0715 9.08835 15.1177 10.45C15.1701 11.9225 16.2359 13.167 17.6684 13.4104C18.2629 13.5089 18.8821 13.5089 19.4551 13.6784C21.2295 14.2082 22.2522 15.6376 22.397 17.6184C22.4278 18.0404 22.5141 18.4779 22.6712 18.8691C23.3181 20.4925 25.3205 21.1949 26.8946 20.3816C28.4873 19.5591 29.0572 17.5445 28.1238 16.0535C27.4984 15.0523 26.565 14.581 25.4221 14.5409C24.6828 14.5163 23.9989 14.3592 23.3705 13.9803C21.9503 13.1177 21.3404 11.8116 21.3065 10.185C21.2726 8.63243 19.7508 7.2123 18.266 7.29547H18.2629Z" fill="url(#paint3_linear_1588_1641)"/>
                  <path d="M11.2916 6.34654C13.1183 6.32805 14.3228 4.5629 14.2335 3.09656C14.1318 1.43922 12.5823 0.0652984 10.9743 0.176198C9.19063 0.29942 7.89063 1.81505 8.03542 3.60793C8.1648 5.19441 9.68043 6.46976 11.2916 6.34654Z" fill="url(#paint4_linear_1588_1641)"/>
                  <path d="M25.5147 13.5858C27.3415 13.5673 28.546 11.8022 28.4566 10.3358C28.355 8.67848 26.8055 7.30456 25.1974 7.41546C23.4138 7.53868 22.1138 9.05431 22.2586 10.8472C22.3879 12.4337 23.9036 13.709 25.5147 13.5858Z" fill="url(#paint5_linear_1588_1641)"/>
                  <path d="M3.91363 20.5389C5.7404 20.5204 6.94489 18.7553 6.85555 17.2889C6.7539 15.6316 5.20438 14.2577 3.59633 14.3686C1.8127 14.4918 0.512705 16.0074 0.657491 17.8003C0.786874 19.3868 2.3025 20.6621 3.91363 20.5389Z" fill="url(#paint6_linear_1588_1641)"/>
                  <path d="M18.192 27.8514C20.0187 27.8329 21.2232 26.0678 21.1339 24.6014C21.0322 22.9441 19.4827 21.5702 17.8747 21.6811C16.091 21.8043 14.791 23.3199 14.9358 25.1128C15.0652 26.6993 16.5808 27.9746 18.192 27.8514Z" fill="url(#paint7_linear_1588_1641)"/>
                  <defs>
                      <linearGradient id="paint0_linear_1588_1641" x1="11.6615" y1="37.5305" x2="17.0802" y2="6.80513" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint1_linear_1588_1641" x1="5.99925" y1="20.9763" x2="10.4691" y2="-4.37349" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint2_linear_1588_1641" x1="11.6703" y1="23.0245" x2="17.089" y2="-7.70398" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint3_linear_1588_1641" x1="18.3399" y1="33.8837" x2="22.8098" y2="8.53385" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint4_linear_1588_1641" x1="9.09205" y1="14.8365" x2="14.0548" y2="-13.3135" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint5_linear_1588_1641" x1="20.8569" y1="36.0307" x2="25.8197" y2="7.88062" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint6_linear_1588_1641" x1="2.98947" y1="21.7865" x2="7.95223" y2="-6.36043" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                      <linearGradient id="paint7_linear_1588_1641" x1="14.7972" y1="43.1094" x2="19.763" y2="14.9624" gradientUnits="userSpaceOnUse">
                      <stop stop-color="#A5F6FF"/>
                      <stop offset="1" stop-color="#5EBABA"/>
                      </linearGradient>
                  </defs>
                </svg>
              </Link>
              <div className='flex gap-[10px] items-center content-center'>
                <div className='w-[1px] h-[37px] bg-[#E1E4ED]'>
                </div>
              </div>

              <div className="flex gap-x-12 ml-20">
                <Link to="/about" className={`flex justify-center items-center gap-4 text-[#454F59] hover:text-[#454f5991] ${location.pathname === "/about" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : "dark:text-[#E1E4ED]"}`}>
                    ABOUT
                </Link>

                <Link to="/jarvis" className={`flex justify-center items-center gap-4 text-[#454F59] hover:text-[#454f5991]  ${location.pathname === "/jarvis" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : "dark:text-[#E1E4ED]"}`}>
                    JARVIS
                </Link>

                <Link to="/careers" className={`flex justify-center items-center gap-4 text-[#454F59] hover:text-[#454f5991]  ${location.pathname === "/careers" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : "dark:text-[#E1E4ED]"}`}>
                    CAREERS
                </Link>

                <Link to="/contact" className={`flex justify-center items-center gap-4 text-[#454F59] hover:text-[#454f5991]  ${location.pathname === "/contact" ? "text-[#5EBABA] font-TTBold dark:text-[#A5F6FF]" : "dark:text-[#E1E4ED]"}`}>
                    CONTACT
                </Link>
              </div>

              <div className="flex gap-x-4 ml-[120px]">
                <Link to="https://auth-app.biorce.com/login" target='_blank' className="flex justify-center items-center text-[#454F59] hover:text-[#454f5991] w-[60px] font-medium font-TTBold dark:text-[#E1E4ED]">
                    Log In
                </Link>

                {/* <Link to="/jarvis" className="flex justify-center items-center gap-4 text-[#454F59] w-[90px] font-medium font-TTBold p-2 rounded-[5px] bg-gradient-to-b from-[rgba(252,252,255,0)] to-[rgba(225,228,237,0.3)] bg-[#F8FAFF] shadow-[0px_2px_4px_rgba(18,55,104,0.12),0px_0px_0px_1px_rgba(18,55,104,0.05),0px_1px_0px_rgba(18,55,104,0.05),0px_0px_0px_1px_rgba(255,255,255,0.5)_inset,0px_1px_0px_#FFF_inset] dark:bg-black dark:text-[#F8FAFF]">
                    Sign Up
                </Link> */}
              </div>
            </div>
          </div>
        </nav>

      </header>
  )
}