import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App.tsx'
import './index.css'
import { MemberstackProvider } from "@memberstack/react"

const config = {
  publicKey: import.meta.env.VITE_MS_TEST_API_KEY 
};

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <MemberstackProvider config={config}>
      <App />
    </MemberstackProvider>
  </React.StrictMode>,
)
