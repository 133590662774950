import { useRef, useEffect, useState } from 'react'

const ButtonMouseScroll = () => {

    const [mouseScrollVisible, setMouseScrollVisible] = useState(false);
    const animatedElementRef = useRef(null)

    useEffect(() => {
    const timer = setTimeout(() => {
        setMouseScrollVisible(true)
    }, 5000)
    return () => clearTimeout(timer)
    }, [mouseScrollVisible])

    const handleScroll = () => {
        setMouseScrollVisible(false)
    }

    useEffect(() => {
      const element:any = animatedElementRef.current
      let startTime:any
  
      const animate = (time:any) => {
        if (!startTime) startTime = time
        const elapsed = time - startTime
        const movement = Math.sin(elapsed / 250) * 5
        element.style.transform = `translateY(${movement}px)`
        requestAnimationFrame(animate)
      }
  
      requestAnimationFrame(animate)
  
      return () => { 
        element.style.transform = 'translateY(0)'
      }

    }, [])
    
    window.addEventListener('scroll', handleScroll)

  return ( 
    <div className="fixed left-1/2 mx-auto hidden md:block md:bottom-[20px] z-50">
        <div className={`hidden md:block w-[50px] h-[50px] justify-center items-center mx-auto ${mouseScrollVisible ? 'block opacity-100' : 'opacity-0'} duration-1000`}>
            <svg xmlns="http://www.w3.org/2000/svg" width="38" height="48" viewBox="0 0 38 48" fill="none">
                <g filter="url(#filter0_b_4398_609)">
                <path d="M37.72 29.3333V18.6667C37.72 8.35735 29.3627 0 19.0534 0C8.74407 0 0.386719 8.35735 0.386719 18.6667V29.3333C0.386719 39.6426 8.74407 48 19.0534 48C29.3627 48 37.72 39.6426 37.72 29.3333Z" fill="#A5F6FF" fill-opacity="0.08"/>
                <path d="M36.6878 18.6667V29.3333C36.6878 39.0725 28.7926 46.9677 19.0534 46.9677C9.31417 46.9677 1.41898 39.0725 1.41898 29.3333V18.6667C1.41898 8.92745 9.31417 1.03226 19.0534 1.03226C28.7926 1.03226 36.6878 8.92745 36.6878 18.6667Z" stroke="url(#paint0_linear_4398_609)" stroke-width="2.06452"/>
                </g>
                <defs>
                <filter id="filter0_b_4398_609" x="-20.2584" y="-20.6452" width="78.6224" height="89.2903" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix"/>
                <feGaussianBlur in="BackgroundImageFix" stdDeviation="10.3226"/>
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_4398_609"/>
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_4398_609" result="shape"/>
                </filter>
                <linearGradient id="paint0_linear_4398_609" x1="19.0534" y1="0" x2="19.0534" y2="48" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5EBABA" stop-opacity="0.5"/>
                <stop offset="1" stop-color="#A5F6FF" stop-opacity="0.1"/>
                </linearGradient>
                </defs>
            </svg>
            <div
            ref={animatedElementRef}
            className="w-[5px] h-[10px] bg-[#001A4F] dark:bg-white rounded-[10px] absolute mt-[-34px] ml-[17px]"
            >
            </div>
        </div>
    </div>
  )
}

export default ButtonMouseScroll