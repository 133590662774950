import { Link, Element } from 'react-scroll'

import SectionTitle from "../../components/sectionTitle/SectionTitle"

const TermsOfUse = () => {
    document.title = "Biorce - Terms of use"
      

  return (
    <div className="flex flex-col justify-center items-center mx-10 lg:mx-20 mb-20 max-w-7xl xl:mx-auto">

      <h1 className="text-[#001A4F] dark:text-[#99ECF3] text-[25px] md:text-[50px] lg:text-[59px] mt-[50px] md:mt-[150px] mb-[30px] font-TTBold">General Terms and Conditions of Use for Biorce</h1>

      <div className="flex flex-col gap-[20px] md:gap-[30px] w-full text-start mb-[50px]">
        <Link 
        smooth={true} 
        duration={800} 
        offset={-100}
        to="1Terms" 
        className="text-[#001A4F80] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] hover:text-[#001a4f] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">1. Introduction </Link>
        <Link 
        smooth={true} 
        duration={800} 
        offset={-100}
        to="2Terms" 
        className="text-[#001A4F80]  hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5  cursor-pointer">2. Definitions  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="3Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">3. Scope of Services  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="4Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">4. Changes to the Terms  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="5Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">5. Accessing the Service  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="6Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">6. Intellectual Property Rights  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="7Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">7. User Contributions  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="8Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">8. Prohibited Uses  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="9Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">9. Disclaimer of Warranties; Limitation of Liability  </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="10Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">10. Indemnification </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="11Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">11. Governing Law </Link>
        <Link
        smooth={true} 
        duration={800} 
        offset={-100} 
        to="12Terms" 
        className="text-[#001A4F80] hover:text-[#001a4f] dark:text-[#99ECF380] hover:dark:text-[#99ECF3] text-[20px] md:text-[30px] font-medium leading-5 cursor-pointer">12. Contact Information </Link>
      </div>

      <SectionTitle 
            title= "Terms & conditions" 
            icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                    <path className='dark:stroke-[#99ECF3]' d="M8.5 5H9.5M8.5 7H9.5M4.5 9H9.5M4.5 11H9.5M11.5 5H13.75C14.1642 5 14.5 5.33579 14.5 5.75V12C14.5 12.8284 13.8284 13.5 13 13.5M11.5 5V12C11.5 12.8284 12.1716 13.5 13 13.5M11.5 5V3.25C11.5 2.83579 11.1642 2.5 10.75 2.5H3.25C2.83579 2.5 2.5 2.83579 2.5 3.25V12C2.5 12.8284 3.17157 13.5 4 13.5H13M4.5 5H6.5V7H4.5V5Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            }
        />

      <div className="flex flex-col gap-[50px] w-full text-start">
        <Element className="flex flex-col w-full" name="1Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">1. Introduction </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">These Terms and Conditions of Use ("Terms") govern your access to and use of our website and services. By accessing or using our services, you agree to be bound by these Terms and any additional terms and conditions and policies referenced herein. If you do not agree to all the terms and conditions of this agreement, then you may not access the website or use any services.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="2Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">2. Definitions  </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">In these Terms, the following definitions apply:<br/>
            <strong className="font-TTBold">"Service"</strong> refers to the application, website, content, and services controlled by Biorce.<br/>
            <strong className="font-TTBold">"User,"</strong> "you," and "your" refer to the individual person, company, or organization that has visited or is using the website and/or Service.<br/>
            <strong className="font-TTBold">"Content"</strong> means any text, images, video, audio, or other multimedia content, software, or otherinformation or material submitted to or on the Service.<br/>
            <strong className="font-TTBold">"User Content"</strong> means any content that users upload, share, or create while using the Service.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="3Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">3. Scope of Services </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Biorce provides AI-based machine learning platform that significantly reduces both the cost and time associated with conducting clinical trials. These services are subject to the terms and conditions set forth herein.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="4Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">4. Changes to the Terms </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">We reserve the right to modify or replace these Terms at any time at our sole discretion. We will provide notice of any changes by posting the new Terms on our Website. It is your responsibility to review these Terms periodically for changes. Your continued use of the Service following the posting of any changes to these Terms constitutes acceptance of those changes.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="5Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">5. Accessing the Service </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] "><strong className="font-TTBold">A.</strong> To access the Service or some of the resources it offers, you may be asked to provide certain registration details or other information. It is a condition of your use of the Service that all the information you provide is correct, current, and complete.<br/><br/>
            <strong className="font-TTBold">B.</strong> You are responsible for making all arrangements necessary for you to have access to the Service and ensuring that all persons who access the Service through your internet connection are aware of these Terms and comply with them.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="6Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">6. Intellectual Property Rights </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">The Service and its original content (excluding content provided by users), features, and functionality are and will remain the exclusive property of Biorce and its licensors. Our Service is protected by copyright, trademark, and other laws of both the European Union and foreign countries. Our trademarks and trade dress may not be used in connection with any product or service without the prior written consent of Biorce.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="7Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">7. User Contributions </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">
            <strong className="font-TTBold">A.</strong> The Service may contain message boards, chat rooms, personal web pages or profiles, forums, bulletin boards, and other interactive features (collectively, "Interactive Services") that allow users to post, submit, publish, display, or transmit to other users or other persons content or materials (collectively, "User Contributions") on or through the Service. <br/><br/>
            <strong className="font-TTBold">B.</strong> All User Contributions must comply with the Content Standards set out in these Terms. <br/><br/> 
            <strong className="font-TTBold">C.</strong> Any User Contribution you post to the site will be considered non-confidential and non- proprietary. By providing any User Contribution on the Service, you grant us and our affiliates and service providers, and each of their and our respective licensees, successors, and assigns the right to use, reproduce, modify, perform, display, distribute, and otherwise disclose to third parties any such material for any purpose.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="8Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">8. Prohibited Uses </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">You may use the Service only for lawful purposes and in accordance with these Terms. You agree not to use the Service:<br/><br/>
            • In any way that violates any applicable federal, state, local, or international law or regulation.<br/>
            • To exploit, harm, or attempt to exploit or harm minors in any way by exposing them to inappropriate content, asking for personally identifiable information, or otherwise.<br/>
            • To send, knowingly receive, upload, download, use, or re-use any material that does not comply with the Content Standards set out in these Terms.<br/>
            • To transmit, or procure the sending of, any advertising or promotional material without our prior written consent, including any "junk mail," "chain letter," "spam," or any other similar solicitation.<br/> 
            • To impersonate or attempt to impersonate Biorce, a Biorce employee, another user, or any other person or entity.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="9Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">9. Disclaimer of Warranties; Limitation of Liability </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">We do not guarantee, represent, or warrant that your use of our service will be uninterrupted, timely, secure, or error-free. We do not warrant that the results that may be obtained from the use of the service will be accurate or reliable. 

            In no case shall Biorce, our directors, officers, employees, affiliates, agents, contractors, interns, suppliers, service providers, or licensors be liable for any injury, loss, claim, or any direct, indirect, incidental, punitive, special, or consequential damages of any kind, including, without limitation, lost profits, lost revenue, lost savings, loss of data, replacement costs, or any similar damages, whether based in contract, tort (including negligence), strict liability or otherwise, arising from your use of any of the service or any products procured using the service, or for any other claim related in any way to your use of the service or any product, including, but not limited to, any errors or omissions in any content, or any loss or damage of any kind incurred as a result of the use of the service or any content posted, transmitted, or otherwise made available via the service, even if advised of their possibility.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="10Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">10. Indemnification </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">You agree to indemnify, defend, and hold harmless Biorce and our parent, subsidiaries, affiliates, partners, officers, directors, agents, contractors, licensors, service providers, subcontractors, suppliers, interns, and employees, from any claim or demand, including reasonable attorneys’ fees, made by any third-party due to or arising out of your breach of these Terms or the documents they incorporate by reference, or your violation of any law or the rights of a third-party.  </p>
        </Element>

        <Element className="flex flex-col w-full" name="11Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">11. Governing Law </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">These Terms shall be governed and construed in accordance with the laws of Portugal, withoutregard to its conflict of law provisions.  </p>
        </Element>

        <Element className="flex flex-col w-full " name="12Terms">
            <h2 className="text-[#001A4F80] dark:text-[#99ECF3] text-[32px] font-bold leading-[80px] ">12. Contact Information </h2>
            <p className="text-[#001A4F80] dark:text-[#99ECF3] text-[22px] font-normal leading-[25px] ">Questions about the Terms of Service should be sent to us at info@biorce.com </p>
        </Element>

      </div>

    </div>
  )
}

export default TermsOfUse
