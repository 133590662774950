
const TitleAndText = () => {
  return (
        <div className="flex flex-col items-start text-left px-0 mx-10 lg:mx-20 mb-[100px] lg:mb-[300px] max-w-7xl 2xl:mx-auto">
            <h3 className="text-[#001A4F] text-[16px] md:text-[20px] lg:text-[28px] font-normal uppercase leading-[70px] dark:text-[#99ECF3]">
                PRO BONO
            </h3>
            <h3 className="text-[#001A4F] text-[40px] md:text-[60px] lg:text-[80px] font-normal leading-[40px] md:leading-[60px] lg:leading-[75px] dark:text-[#99ECF3] mb-5">
                Opening doors,<br/> 
                to <strong className="font-TTBold"> healthcare innovation. </strong></h3>
            <p className="self-stretch text-[#001A4F] text-[18px] md:text-[20px] lg:text-[23px] font-normal leading-[20px] md:leading-[30px] lg:leading-[35px] dark:text-[#99ECF3]">
            At Biorce, we believe healthcare innovation shouldn't have a price tag. That's why we offer free access to our AI platform for academics & donate profits to patients in need. Together, let's revolutionize healthcare for all!<br/>
            Biorce is working on bringing our platform for free to all academia, granting investigators access to state of the art technology 
            at no cost.<br/>
            Biorce has pledged to donate a portion of all its profits back to patients without access to healthcare
            In line with our mission to bring access to innovative drugs faster and cheaper.<br/>
            </p>
        </div>
  )
}

export default TitleAndText
