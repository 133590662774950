import { Link } from "react-router-dom"
import "./WeAreHiring.css"


const WeAreHiring = () => {
  return (

    <div className="mt-10 mx-10 lg:mx-20 mb-[100px] lg:mb-[300px] max-w-7xl 2xl:mx-auto">
        <div className="flex flex-col px-0 md:px-10 lg:px-10  justify-center items-start gap-6 self-stretch rounded-xl border border-[#62BDBE] bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0.00)_98.58%),_linear-gradient(114deg,_rgba(254,_254,_254,_0.50)_0%,_rgba(254,_254,_254,_0.00)_100%)] backdrop-blur-[12px] dark:bg-[linear-gradient(114deg,_rgba(94,_186,_186,_0.03)_1.41%,_rgba(94,_186,_186,_0.00)_98.58%),_linear-gradient(114deg,_rgba(41,_38,_38,_0.50)_0%,_rgba(254,_254,_254,_0.00)_100%)]
         p-5 pb-10 md:pb-5 lg:pb-10 lg:p-10 ">
            <div className="block md:flex lg:flex px-2 md:px-4 lg:px-24 justify-center items-center gap-36 md:gap-10 lg:gap-36 mx-auto">
                <div className="flex flex-col p-2 items-start gap-5 ">
                    <h3 className="self-stretch text-[#001A4F] text-[25px] md:text-[35px] lg:text-[48px] font-normal leading-[56px] dark:text-[#99ECF3] text-center">WE ARE <strong className="font-TTBold">HIRING</strong></h3>

                </div>

                <div className="weAreHiringBTN  w-[280px] lg:w-[325px] mx-auto">
                    <Link to="https://www.linkedin.com/company/biorce/jobs/" target="_blank" className="flex justify-between items-center flex-grow flex-shrink-0 basis-0 mx-auto">
                        <div className="absolute left-[5px] p-2 md:px-4 justify-center items-center gap-2.5 text-[#001A4F] lg:text-[#001a4f7b]  hover:text-[#001A4F] text-base font-bold leading-6 mx-auto">
                            <h4 className="dark:text-[#99ECF3] lg:dark:text-[#99ecf377] hover:dark:text-[#99ECF3] font-TTBold">
                                View opportunities 
                            </h4>
                        </div>
                        <div className="weAreHiringBTNIcon absolute right-[5px]">
                            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <path className='dark:stroke-[#99ECF3]' d="M9.99831 5.51601C12.5856 5.12863 15.2064 5.07776 17.7953 5.36385C18.0173 5.38839 18.2152 5.48747 18.3637 5.63594M18.4836 14.0013C18.871 11.414 18.9218 8.79321 18.6358 6.20434C18.6112 5.98226 18.5121 5.7844 18.3637 5.63594M18.3637 5.63594L5.63574 18.3639" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                    </Link>
                </div>
                

            </div>
        </div>
    </div>

  )
}

export default WeAreHiring
