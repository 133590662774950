
import { useEffect, useRef } from 'react'
import "./CasesStudiesCardsAnimation.css"

const applyCardAnimationObserver = (element:any, delay:any) => {
  let timeoutId:any = null

  const observer = new IntersectionObserver(
    ([entry]) => {
      if (entry.isIntersecting) {
        if (timeoutId) {
          clearTimeout(timeoutId)
          timeoutId = null
        }
        timeoutId = setTimeout(() => {
          element.classList.add('show')
        }, delay * 200)
      } else {
        if (timeoutId) {
          clearTimeout(timeoutId);
          timeoutId = null
        }
        element.classList.remove('show')
      }
    },
    {
      threshold: 0.1,
    }
  )

  observer.observe(element)

  return () => {
    observer.unobserve(element)
    if (timeoutId) {
      clearTimeout(timeoutId)
      timeoutId = null
    }
  }
}

const CasesStudiesCardsAnimation = () => {

    const delay1 = 1000
    const delay2 = 1000
    const delay3 = 1000
  
    const ref1 = useRef(null)
    const ref2 = useRef(null)
    const ref3 = useRef(null)
  
    useEffect(() => {
      const cleanup1 = ref1.current ? applyCardAnimationObserver(ref1.current, delay1 / 1000) : undefined
      const cleanup2 = ref2.current ? applyCardAnimationObserver(ref2.current, delay2 / 1000) : undefined
      const cleanup3 = ref3.current ? applyCardAnimationObserver(ref3.current, delay3 / 1000) : undefined
  
      return () => {
        if (cleanup1) cleanup1()
        if (cleanup2) cleanup2()
        if (cleanup3) cleanup3()
      };
    }, [delay1, delay2, delay3])

  return (
    <div className="block lg:flex items-center justify-center mb-[120px] md:mb-[400px] lg:mb-[300px] w-full h-[900px] md:h-[300px] lg:h-[400px]">

        <div ref={ref1} className="homeCardAnimation px-4 py-0 log:py-10 flex flex-col justify-center items-center self-stretch">
            <div className="card rounded-[18px] border border-custom bg-custom-gradient backdrop-filter backdrop-blur-17.5 p-4 lg:p-10 w-[250px] lg:w-[400px] h-[300px] lg:h-[400px]">
                <div className="flex items-center justify-center gap-[10px]"> 
                    <svg className='w-[50px] lg:w-[105px] h-[50px] lg:h-[105px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 105" fill="none">
                        <path className='dark:stroke-[#99ECF3]' d="M36.753 36.2535L18.9704 54.036C9.99749 63.009 9.99748 77.557 18.9704 86.53C27.9434 95.5029 42.4914 95.5029 51.4644 86.53L69.2469 68.7474M36.753 36.2535L54.5355 18.4709C63.5085 9.49797 78.0565 9.49797 87.0295 18.4709C96.0024 27.4439 96.0024 41.9919 87.0295 50.9649L69.2469 68.7474M36.753 36.2535L69.2469 68.7474M59.1611 37.0578L66.8951 29.3239C68.9156 27.3034 71.9257 26.8758 74.365 28.0411" stroke="url(#paint0_linear_1464_1985)" stroke-width="8.83019" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                            <linearGradient id="paint0_linear_1464_1985" x1="53" y1="11.7412" x2="53" y2="93.2597" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#001A4F"/>
                            <stop offset="0.25" stop-color="#001A4F" stop-opacity="0.83"/>
                            <stop offset="1" stop-color="#001A4F" stop-opacity="0.35"/>
                            </linearGradient>
                        </defs>
                    </svg> 
                </div>
                <div className="flex p-2 justify-center items-center gap-2.5">
                    <h3 className="text-[#001A4F] text-[48px] font-semibold leading-[56px] my-5 mx-auto dark:text-[#99ECF3]">Industry </h3>
                </div>
                <div className="flex p-1 justify-center items-center gap-2.5 self-stretch w-[220px] lg:w-[300px]">
                    <p className="text-[#22272B] text-base font-normal leading-6 text-center dark:text-white"> Enabling smart study design, protocol development, feasibility analysis, and targeted patient recruitment </p>
                </div>
            </div>
        </div>

        <div ref={ref2} className="homeCardAnimation2 px-4 py-0 log:py-10 flex flex-col justify-center items-center self-stretch">
            <div className="card rounded-[18px] border border-custom bg-custom-gradient backdrop-filter backdrop-blur-17.5  p-4 lg:p-10 w-[250px] lg:w-[400px] h-[300px] lg:h-[400px]">
                <div className="flex items-center justify-center gap-[10px] "> 
                    <svg className='w-[60px] lg:w-[105px] h-[60px] lg:h-[105px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 106 105" fill="none">
                        <path className='dark:stroke-[#99ECF3]' d="M88 34.9947H90.1575C92.2183 34.9947 93.2487 34.9947 94.1052 35.1675C97.5575 35.8639 100.256 38.5622 100.952 42.0145C101.125 42.871 101.125 43.9014 101.125 45.9622C101.125 47.8148 101.125 48.7411 101.011 49.6019C100.556 53.0213 98.7739 56.1243 96.0493 58.2398C95.3634 58.7723 94.5633 59.2391 92.963 60.1725L87.927 63.1102M35.5 43.7447H70.5M41.3333 61.2447H64.6667M87.927 63.1102C88 62.1307 88 60.9196 88 59.1098V36.3708C88 33.05 88 31.3896 87.5052 29.9815C86.8647 28.1587 85.6389 26.5991 84.019 25.546C82.7676 24.7325 81.1542 24.3403 77.9274 23.5557C69.5882 21.528 65.4185 20.5142 61.208 20.0171C55.7549 19.3732 50.2451 19.3732 44.792 20.0171C40.5815 20.5142 36.4118 21.528 28.0726 23.5557C24.8458 24.3403 23.2324 24.7325 21.981 25.546C20.3611 26.5991 19.1353 28.1587 18.4948 29.9815C18.0736 31.1802 18.0109 32.5617 18.0016 34.9947M87.927 63.1102C87.8897 63.6112 87.8333 64.0516 87.748 64.4806C87.2321 67.0746 85.9446 69.4524 84.0541 71.3021C83.1305 72.2059 81.985 72.9534 79.6942 74.4484C72.4063 79.2047 68.7624 81.5828 64.9858 82.9593C57.2444 85.781 48.7556 85.781 41.0142 82.9593C37.2376 81.5828 33.5937 79.2047 26.3058 74.4484C24.015 72.9534 22.8695 72.2059 21.9459 71.3021C20.0554 69.4524 18.7679 67.0746 18.252 64.4806C18.1667 64.0516 18.1103 63.6112 18.073 63.1102M18.073 63.1102C18 62.1307 18 60.9196 18 59.1098V36.3708C18 35.8769 18 35.4198 18.0016 34.9947M18.073 63.1102L13.037 60.1725C11.4367 59.2391 10.6366 58.7723 9.95073 58.2398C7.22613 56.1243 5.44385 53.0213 4.9894 49.6019C4.875 48.7411 4.875 47.8148 4.875 45.9622C4.875 43.9014 4.875 42.871 5.04776 42.0145C5.74415 38.5622 8.44249 35.8639 11.8948 35.1675C12.7513 34.9947 13.7817 34.9947 15.8425 34.9947H18.0016" stroke="url(#paint0_linear_1464_1994)" stroke-width="8.83019" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                            <linearGradient id="paint0_linear_1464_1994" x1="53" y1="19.5342" x2="53" y2="85.0756" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#001A4F"/>
                            <stop offset="0.25" stop-color="#001A4F" stop-opacity="0.83"/>
                            <stop offset="1" stop-color="#001A4F" stop-opacity="0.35"/>
                            </linearGradient>
                        </defs>
                    </svg>
                </div>
                <div className="flex p-2 justify-center items-center gap-2.5">
                    <h3 className="text-[#001A4F] text-[38px] lg:text-[48px] font-semibold leading-[56px] my-5 mx-auto dark:text-[#99ECF3]"> Patients </h3>
                </div>
                <div className="flex p-1 justify-center items-center gap-2.5 self-stretch w-[220px] lg:w-[300px]">
                    <p className="text-[#22272B] text-[14px] lg:text-base font-normal leading-6 text-center dark:text-white"> Empowering patients to find available trials and reduce the burden </p>
                </div>
            </div>
        </div>

        <div ref={ref3} className="homeCardAnimation3 px-4 py-0 log:py-10 flex flex-col justify-center items-center self-stretch">
            <div className="card rounded-[18px] border border-custom bg-custom-gradient backdrop-filter backdrop-blur-17.5 p-4 lg:p-10 w-[250px] lg:w-[400px] h-[300px] lg:h-[400px] ">
                <div className="flex items-center justify-center gap-[10px]"> 
                    <svg className='w-[60px] lg:w-[105px] h-[60px] lg:h-[105px]' xmlns="http://www.w3.org/2000/svg" width="105" height="105" viewBox="0 0 106 105" fill="none">
                        <path className='dark:stroke-[#99ECF3]' d="M46.4375 13.1768C52.4781 13.1768 57.375 18.0736 57.375 24.1143V31.4833C57.375 39.4428 54.5845 47.1504 49.4889 53.2651L48.4138 54.5552C45.0535 58.5876 40.2767 60.6037 35.5 60.6037M24.5625 13.1768C18.5219 13.1768 13.625 18.0736 13.625 24.1143V31.4833C13.625 39.4428 16.4155 47.1504 21.5111 53.2651L22.5862 54.5552C25.9465 58.5876 30.7233 60.6037 35.5 60.6037M83.625 61.3018C89.6656 61.3018 94.5625 56.4049 94.5625 50.3643C94.5625 44.3236 89.6656 39.4268 83.625 39.4268C77.5844 39.4268 72.6875 44.3236 72.6875 50.3643C72.6875 56.4049 77.5844 61.3018 83.625 61.3018ZM83.625 61.3018V67.8643C83.625 81.1536 72.8519 91.9268 59.5625 91.9268C46.2731 91.9268 35.5 81.1536 35.5 67.8643V60.6037" stroke="url(#paint0_linear_1464_2003)" stroke-width="9.16667" stroke-linecap="round" stroke-linejoin="round"/>
                        <defs>
                            <linearGradient id="paint0_linear_1464_2003" x1="54.0938" y1="13.1768" x2="54.0938" y2="91.9268" gradientUnits="userSpaceOnUse">
                            <stop stop-color="#001A4F"/>
                            <stop offset="0.25" stop-color="#001A4F" stop-opacity="0.83"/>
                            <stop offset="1" stop-color="#001A4F" stop-opacity="0.35"/>
                            </linearGradient>
                        </defs>
                    </svg> 
                </div>
                <div className="flex p-2 justify-center items-center gap-2.5">
                    <h3 className="text-[#001A4F] text-[38px] lg:text-[48px] font-semibold leading-[56px] my-5 mx-auto dark:text-[#99ECF3]"> Physicians </h3>
                </div>
                <div className="flex p-1 justify-center items-center gap-2.5 self-stretch w-[220px] lg:w-[300px]">
                    <p className="text-[#22272B] text-base font-normal leading-6 text-center dark:text-white"> Identify available trials for patients </p>
                </div>
            </div>
        </div>

    </div>
  )
}

export default CasesStudiesCardsAnimation