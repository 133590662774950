import BannerText from "../../components/bannerText/BannerText"
import SectionTitle from "../../components/sectionTitle/SectionTitle"
import CardsCarousel from "../../components/cardsCarousel/CardsCarousel"
import WeAreHiring from "../../components/weAreHiring/WeAreHiring"
import TitleAndText from "../../components/titleAndText/TitleAndText"
import TextBlocksAnimation from "../../components/textBlocksAnimation/TextBlocksAnimation"
import WhatWeAreAnimation from "../../components/whatWeAreAnimation/WhatWeAreAnimation"
import Partnerships from "../../components/partnerships/Partnerships"


const About = () => {
  document.title = "Biorce - About"
  
  return (
    <div>
       <div>
          <BannerText/>
          
          <SectionTitle 
              title = "Vision" 
              icon = {
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M14.5 7.99967C14.5 9.33301 12.1667 12.6663 8.5 12.6663C4.83333 12.6663 2.5 9.33301 2.5 7.99967C2.5 6.66634 4.83333 3.33301 8.5 3.33301C12.1667 3.33301 14.5 6.66634 14.5 7.99967Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                  <path className='dark:stroke-[#99ECF3]' d="M10.5 7.99967C10.5 9.10424 9.60457 9.99967 8.5 9.99967C7.39543 9.99967 6.5 9.10424 6.5 7.99967C6.5 6.89511 7.39543 5.99967 8.5 5.99967C9.60457 5.99967 10.5 6.89511 10.5 7.99967Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }
          />

          <TextBlocksAnimation/>

          <SectionTitle 
              title = "We are" 
              icon = {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M3.33398 2L3.33398 14M4.31525 9.01111C5.50499 8.60938 6.80977 8.73601 7.90005 9.35903C8.9565 9.96272 10.216 10.1012 11.3784 9.7414L12.2513 9.47121C12.4987 9.39465 12.6673 9.1659 12.6673 8.90696V2.99011C12.6673 2.39712 11.5564 2.94555 11.3039 3.02371C10.1936 3.36738 8.98889 3.21347 8.00065 2.6017C7.01242 1.98994 5.8077 1.83603 4.69741 2.17969L3.73322 2.47813C3.49584 2.55161 3.33398 2.77113 3.33398 3.01963V8.97072C3.33398 9.47453 4.09263 9.08628 4.31525 9.01111Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }
          />

          <p className="mb-[50px] mx-10 lg:mx-20 text-[#001A4F] text-[18px] lg:text-[30px] font-light leading-tight dark:text-[#99ECF3] max-w-7xl xl:mx-auto">
            <strong className="font-TTBold font-normal">We are</strong> focused pioneers, relentless in our pursuit of innovation. A fusion of scientific brilliance and technological prowess, our team is driven by an insatiable curiosity to redefine the boundaries of healthcare. We are dreamers, doers, and change-makers, united by a common goal: to revolutionise clinical trials and improve patient outcomes.
          </p>

          <WhatWeAreAnimation/>

          <SectionTitle 
              title = "Team" 
              icon = {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M4.88574 5.46082C3.93006 5.46082 3.15532 4.68609 3.15532 3.73041C3.15532 2.77473 3.93006 2 4.88574 2C5.84142 2 6.61615 2.77473 6.61615 3.73041C6.61615 4.68609 5.84142 5.46082 4.88574 5.46082ZM4.88574 5.46082C6.17327 5.46082 7.31152 6.1 8.00048 7.07836C8.68943 6.1 9.82768 5.46082 11.1152 5.46082M4.88574 5.46082C3.5982 5.46082 2.45995 6.1 1.771 7.07836M11.1152 5.46082C10.1595 5.46082 9.38481 4.68609 9.38481 3.73041C9.38481 2.77473 10.1595 2 11.1152 2C12.0709 2 12.8456 2.77473 12.8456 3.73041C12.8456 4.68609 12.0709 5.46082 11.1152 5.46082ZM11.1152 5.46082C12.4028 5.46082 13.541 6.1 14.23 7.07836M4.88574 12.3825C3.93006 12.3825 3.15532 11.6077 3.15532 10.6521C3.15532 9.69638 3.93006 8.92164 4.88574 8.92164C5.84142 8.92164 6.61615 9.69638 6.61615 10.6521C6.61615 11.6077 5.84142 12.3825 4.88574 12.3825ZM4.88574 12.3825C6.17327 12.3825 7.31152 13.0216 8.00048 14C8.68943 13.0216 9.82768 12.3825 11.1152 12.3825M4.88574 12.3825C3.5982 12.3825 2.45995 13.0216 1.771 14M11.1152 12.3825C10.1595 12.3825 9.38481 11.6077 9.38481 10.6521C9.38481 9.69638 10.1595 8.92164 11.1152 8.92164C12.0709 8.92164 12.8456 9.69638 12.8456 10.6521C12.8456 11.6077 12.0709 12.3825 11.1152 12.3825ZM11.1152 12.3825C12.4028 12.3825 13.541 13.0216 14.23 14" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }
          />

          <CardsCarousel/>

          <SectionTitle 
              title = "Pro Bono" 
              icon = {
                <svg xmlns="http://www.w3.org/2000/svg" width="17" height="16" viewBox="0 0 17 16" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M8.5 4V12M6.5 10.1212L7.08592 10.5607C7.86693 11.1464 9.13322 11.1464 9.91424 10.5607C10.6953 9.97487 10.6953 9.02513 9.91424 8.43934C9.52369 8.14642 9.0118 7.99997 8.49992 8C8.01664 8.00003 7.53347 7.8536 7.16473 7.56068C6.42733 6.97489 6.42733 6.02514 7.16473 5.43936C7.90213 4.85357 9.09769 4.85357 9.83509 5.43936L10.1117 5.65908M14.5 8C14.5 11.3137 11.8137 14 8.5 14C5.18629 14 2.5 11.3137 2.5 8C2.5 4.68629 5.18629 2 8.5 2C11.8137 2 14.5 4.68629 14.5 8Z" stroke="#001A4F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }
          />

          <TitleAndText/>

          <SectionTitle 
              title= "Partnerships" 
              icon= {
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                      <path className='dark:stroke-[#99ECF3]' d="M10.2344 14H3.33333C2.59695 14 2 13.403 2 12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333M12 12V10M12 10V8M12 10H10M12 10H14M10 4.66667C10 6.13943 8.80609 7.33333 7.33333 7.33333C5.86057 7.33333 4.66667 6.13943 4.66667 4.66667C4.66667 3.19391 5.86057 2 7.33333 2C8.80609 2 10 3.19391 10 4.66667Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  </svg>
              }
          />
                    
          <Partnerships/>

          <SectionTitle 
              title= "Careers" 
              icon= {
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                  <path className='dark:stroke-[#99ECF3]' d="M10.2344 14H3.33333C2.59695 14 2 13.403 2 12.6667C2 11.1939 3.19391 10 4.66667 10H7.33333M12 12V10M12 10V8M12 10H10M12 10H14M10 4.66667C10 6.13943 8.80609 7.33333 7.33333 7.33333C5.86057 7.33333 4.66667 6.13943 4.66667 4.66667C4.66667 3.19391 5.86057 2 7.33333 2C8.80609 2 10 3.19391 10 4.66667Z" stroke="#001A4F" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              }
          />
          <WeAreHiring/>
        </div>
    </div>
  )
}

export default About
