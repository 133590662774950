
const BannerText = () => {
  return (
    <div className="mx-10 lg:mx-20 my-[100px] lg:my-[300px] max-w-7xl xl:mx-auto">
      <h1 className="text-[#001A4F] text-[16px] lg:text-[28px] font-normal leading-[20px] text-left dark:text-[#99ECF3]">WHO WE ARE</h1>
      <div className="flex w-full flex-col justify-center items-start leading-[50px] lg:leading-[110px] text-left mb-[10px] mt-[0px]">
        <h2 className="text-[#001A4F] text-[55px] lg:text-[130px] xl:text-[120px] font-normal dark:text-[#99ECF3]">
            It’s time for a <br/> <strong className="font-TTBold">healthcare revolution.</strong>
        </h2>
        <h2 className="text-[#001A4F] text-[18px] lg:text-[42px] font-normal leading-normal w-full dark:text-[#99ECF3]">
            We are building a future of faster <strong className="font-TTBold">clinical trials, by developing AI.</strong>
        </h2>
      </div>
    </div>
  )
}

export default BannerText
