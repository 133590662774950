import { useLocation, Link } from "react-router-dom"

const TransitionButtons = () => {
    const location = useLocation()
    const currentPath = location.pathname
    const routesDirection = [
        "/home", "/about", "/jarvis", "/careers", "/contact", "/terms-of-use"
    ]
    const currentIndex = routesDirection.indexOf(currentPath)
    const buttonLeft = currentIndex > 0 ? routesDirection[currentIndex - 1] : null
    const buttonRight = currentIndex < routesDirection.length - 1 ? routesDirection[currentIndex + 1] : null

    return (
        <div>
            {buttonLeft && (
                <Link to={buttonLeft}>
                    <div id="transitionButtons" className="group fixed top-[50vh] left-5 p-1 cursor-pointer rounded-full border border-[#5EBABA]/50 hover:border-[#5EBABA] bg-gradient-to-t from-[#A5F6FF]/8 to-[#A5F6FF]/8 backdrop-blur-md z-40">
                        
                        <svg className="hidden lg:block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path className="dark:stroke-white/50 dark:group-hover:stroke-white group-hover:stroke-[#001A4F]" d="M15 18C12.8192 16.423 10.8636 14.5771 9.18284 12.5101C8.93905 12.2103 8.93905 11.7897 9.18284 11.4899C10.8636 9.42294 12.8192 7.57701 15 6" stroke="#001A4F80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <svg className="block lg:hidden" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path className="dark:stroke-[#99ECF3] stroke-[#001A4F]" d="M5.47059 1.95507C5.98742 2.19709 6.46972 2.50429 6.90553 2.86886C6.94291 2.90013 6.96689 2.94202 6.97594 2.98693L6.9764 2.98929M5.98766 4.52124C6.37044 4.09795 6.69613 3.62794 6.95674 3.12305C6.97871 3.0805 6.98478 3.03351 6.9764 2.98929M6.9764 2.98929L6.99197 2.98693M6.9764 2.98929C5.05245 3.28316 3.31227 4.13557 1.92651 5.37585M5.66719 12.1477L4.27191 7.24724C4.18735 6.95027 4.38008 6.64583 4.68469 6.59522C5.62148 6.43952 6.54396 6.94143 6.92217 7.81247L7.48016 9.09756L9.40783 2.84355C9.62076 2.15275 10.3534 1.76535 11.0442 1.97828C11.735 2.19121 12.1224 2.92382 11.9094 3.61461L10.7528 7.36702L13.6055 8.7951C15.697 9.84227 15.0203 12.3673 13.8556 13.875C11.8042 16.5305 6.57349 15.3308 5.66719 12.1477Z" stroke="#001A4F" stroke-width="1.30887" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </Link>
            )}

            {buttonRight && (
                <Link to={buttonRight}>
                    <div id="transitionButtons2" className="group fixed top-[50vh] right-5 p-1 cursor-pointer rounded-full border border-[#5EBABA]/50 hover:border-[#5EBABA] bg-gradient-to-t from-[#A5F6FF]/8 to-[#A5F6FF]/8 backdrop-blur-md z-40">
                        
                        <svg className="hidden lg:block" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path className="dark:stroke-white/50 dark:group-hover:stroke-white group-hover:stroke-[#001A4F]" d="M9 18C11.1808 16.423 13.1364 14.5771 14.8172 12.5101C15.0609 12.2103 15.0609 11.7897 14.8172 11.4899C13.1364 9.42294 11.1808 7.57701 9 6" stroke="#001A4F80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>

                        <svg className="block lg:hidden" xmlns="http://www.w3.org/2000/svg" width="17" height="17" viewBox="0 0 17 17" fill="none">
                            <path className="dark:stroke-[#99ECF3] stroke-[#001A4F]" d="M11.3011 1.95564C10.7843 2.19766 10.302 2.50486 9.8662 2.86943C9.82882 2.9007 9.80484 2.9426 9.79579 2.9875L9.79533 2.98986M10.7841 4.52181C10.4013 4.09852 10.0756 3.62851 9.81499 3.12362C9.79302 3.08107 9.78695 3.03408 9.79533 2.98986M9.79533 2.98986L9.77976 2.9875M9.79533 2.98986C11.7193 3.28374 13.4595 4.13614 14.8452 5.37642M6.35229 13.8695L2.5644 10.4618C2.33485 10.2552 2.33614 9.89492 2.56718 9.69006C3.27767 9.05997 4.32581 8.99437 5.10936 9.53085L6.26536 10.3223L4.57156 4.00098C4.38446 3.30274 4.79883 2.58504 5.49707 2.39795C6.19533 2.21085 6.91301 2.62522 7.1001 3.32346L8.11638 7.11627L11.2916 6.80844C13.6198 6.58282 14.3897 9.08098 14.2052 10.9772C13.8804 14.3171 8.81276 16.0831 6.35229 13.8695Z" stroke="#001A4F" stroke-width="1.30887" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </Link>
            )}
        </div>
    )
}

export default TransitionButtons